@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400&display=swap');

.solution {
    margin-top: 8%;
    margin-bottom: 8%;
}

.solution .heading-holder h4 {
    color: #000000;
    font-size: 50px;
    font-weight: bold;
}

.solution .heading-holder p {
    font-size: 16px;
    max-width: 300px;
    margin: auto;
    color: #00000096;
    font-family: 'Source Sans Pro' !important;
}

.solution .traffic-holder {
    position: relative;
}

.solution .traffic-holder .traffic-name {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    left: 23px;
}

.solution .traffic-holder .traffic-name p {
    font-size: 30px;
    color: #ffffffc7;
}

.solution .traffic-holder .traffic_img {
    width: 100%;
    height: 641px;
    transition: all 0.8s ease;
}

.solution .traffic-holder .low-angle {
    width: 100%;
    height: 274px;
    transition: all 0.8s ease;
}

.solution .traffic-holder .high-voltage {
    width: 100%;
    height: 160px;
    transition: all 0.8s ease;
}

.solution .traffic-holder .highways-high {
    width: 100%;
    height: 176px;
    transition: all 0.8s ease;
}

.solution .traffic-holder .hotel {
    width: 100%;
    height: 351px;
    transition: all 0.8s ease;
}

.solution .traffic-holder:hover .traffic_img {
    border-radius: 20px;

}

.solution .traffic-holder:hover .low-angle {
    border-radius: 20px;

}

.solution .traffic-holder:hover .high-voltage {
    border-radius: 20px;

}

.solution .traffic-holder:hover .highways-high {
    border-radius: 20px;

}

.solution .traffic-holder:hover .hotel {
    border-radius: 20px;

}

.solution .traffic-holder .img-overlay {
    width: 100%;
    height: 0;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 100% no-repeat padding-box;
    border-radius: 20px;
    opacity: 0.7;
    position: absolute;
    bottom: 0;
    transition: height 0.3s ease;
}

.solution .traffic-holder:hover .img-overlay {
    height: 40%;
}

@media(min-width:0px) and (max-width:320px) {

    .solution {
        margin-top: 72%;
    }

    .solution .heading-holder h4 {
        font-size: 29px;

    }

    .home-product-section .heading-holder p {

        font-size: 14px;
    }
}

@media(min-width:321px) and (max-width:576px) {
    .solution {
        margin-top: 72%;
    }

    .solution .heading-holder h4 {
        font-size: 30px;

    }

}

@media(min-width:578px) and (max-width:767px) {
    .solution {
        margin-top: 0%;
    }
}

@media(min-width:0px) and (max-width:767px) {

    .solution .traffic-holder .traffic-name p {
        font-size: 24px;

    }

    .solution .traffic-holder .traffic_img {
        width: 100%;
        height: auto;
    }

    .solution .traffic-holder .low-angle {
        width: 100%;
        height: auto;
    }

    .solution .traffic-holder .highways-high {
        width: 100%;
        height: auto;
    }

    .solution .traffic-holder .hotel {
        width: 100%;
        height: auto;
    }

    .solution .traffic-holder .high-voltage {
        height: auto;
    }

    .solution .heading-holder h4 {
        font-size: 30px;

    }
}

@media(min-width:768px) and (max-width:991px) {
    .solution .traffic-holder .traffic-name p {
        font-size: 18px;
    }

    .solution .traffic-holder .traffic-name {
        left: 11px;
    }

    .solution .traffic-holder .low-angle {
        height: 217px;
    }

    .solution .traffic-holder .traffic_img {
        height: 500px;
    }

    .solution .traffic-holder .high-voltage {
        height: 111px;
    }

    .solution .traffic-holder .highways-high {
        height: 135px;
    }

    .solution .traffic-holder .hotel {
        height: 264px;
    }

    .solution .heading-holder h4 {
        font-size: 45px;

    }
}

@media(min-width:999px) and (max-width:1201px) {
    .solution .traffic-holder .traffic-name p {
        font-size: 27px;
    }
}