.contact-us {
  background-image: url("../../public/assets/images/banner/contact-us.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
  position: relative;
  background-attachment: fixed;
}

.contact-us .circle-holder {
  width: 31px;
  height: 31px;
  background: #ffffff;
  border-radius: 100%;
}

.contact-us .circle-holder:hover {
  background: #ed1b24 0% 0% no-repeat padding-box;
  border: 1px solid #fff;
}

.contact-us .circle-holder:hover .fa-icon {
  color: #fff;
}

.contact-us .address-holder h4 {
  font-size: 20px;
  color: #fff;
}

.contact-us .loate-holder {
  top: 55%;
  transform: translate(0, -50%);
  position: absolute;
  margin-left: 3%;
}

.contact-us .contact-sec {
  margin-bottom: 10%;
}

.contact-us .imag-overlay {
  position: absolute;
  background: transparent linear-gradient(270deg,
      #ed1b24d6 0%,
      #b7151cde 0%,
      #150203f8 0%,
      #c8171f30 0%,
      #090101fa 27%,
      #090101fa 34%,
      #090101fa 55%,
      #020000f5 63%,
      #000000 86%,
      #010101 100%) 0% 0% no-repeat padding-box;
  opacity: 0.78;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
}

.contact-us .form-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #0000004a;
  top: 20%;
  z-index: 1;
  position: absolute;
  width: 590px;
  padding: 25px 39px 39px 39px;
}

.contact-us .heading-about h4 {
  font-size: 25px;
  font-weight: bold;
}

.contact-us .border-red {
  background: #ed1b24;
  height: 3px;
  width: 93px;
}

.contact-us .border-line {
  height: 1px;
  background: #000000;
  width: 115px;
  margin-top: 1px;
}

.contact-us .form-holder .btn-dark {
  box-shadow: 0px 3px 12px #4343433b;
  border-radius: 0%;
  font-size: 18px;
  width: 100%;
  height: 48px;
  background-color: #000;
}

.contact-us .form-holder .btn-dark:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #4343433b;
  border: 1px solid #ed1b24;
  font-size: 18px;
  color: #000;
  font-weight: bold;
}

.contact-us .form-holder .form-control {
  border-radius: 0%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  font-size: 12px;
  color: #000;
}

.contact-us .form-holder .form-label {
  font-size: 16px;
}

/* modal css */
.Contact-PopUp {
  backdrop-filter: blur(1px);
  background: transparent linear-gradient(180deg, #ED1B24D6 0%, #B7151CDE 0%, #15020300 0%, #C8171F30 6%, #09010100 27%,
      #0901010d 34%, #09010100 55%, #01010100 63%, #00000000 86%, #01010100 100%) 0% 0% no-repeat padding-box;
}

.Contact-PopUp .modal-dialog .modal-content .modal-body .circle {
  left: 35%;
  display: flex;
  top: -108px;
  position: absolute;
  border-radius: 76px;
  background-color: white;
  width: 152px;
  height: 152px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Contact-PopUp .modal-dialog .modal-content .modal-header {
  border-bottom: none !important;
}

.Contact-PopUp .modal-dialog .modal-content .modal-body {
  height: 143px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 9px #ed1b2463; */
  border-radius: 25px;
}

.Contact-PopUp .modal-dialog .modal-content .modal-body h1.successfully {
  padding: 40px 0px 0px 0px;
  text-align: center;
  color: #000000;
  font-size: 22px;
}

.Contact-PopUp .modal-dialog .modal-content .modal-body h1.Thanks {
  font-size: 25px;
  color: #ed1b24;
  text-align: center;
  font-weight: bold;
}

/* .Contact-PopUp .modal-body {
  background: #FBFBFB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #ED1B2463;
  border-radius: 25px;
} */

.Contact-PopUp .modal {
  backdrop-filter: blur(6px);
  background: transparent linear-gradient(180deg,
      #a28283d6 0%,
      #decfd0de 0%,
      #a89a9bf8 0%,
      #b6a1a330 6%,
      #5e4949fa 27%,
      #a67e7efa 34%,
      #090101fa 55%,
      #020000f5 63%,
      #000000 86%,
      #010101 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
}

.mobileview-contactus {
  display: none;
}

/* **************************************media query started ************************************ */
@media (min-width: 0px) and (max-width: 576px) {
  .contact-us {
    background-image: none;
    margin-bottom: 9%;
  }

  .contact-us .fa-icon {
    color: #fff;
  }

  .desktopview-contactus {
    display: none;
  }

  .mobileview-contactus {
    display: block;
  }

  .contact-us .loate-holder {
    position: relative;
    margin-top: 5%;
  }

  .contact-us .circle-holder {
    width: 31px;
    height: 31px;
    background: #000;
    border-radius: 100%;
  }

  .contact-us .form-holder {
    margin-top: 20%;
    position: unset;
    width: 100%;

  }

  .contact-us .imag-overlay {
    display: none;
  }



  .contact-us .contact-sec {
    margin-bottom: 4%;
  }

  .contact-us .heading-about h4 {
    font-size: 18px;
  }

  .contact-us .border-line {
    width: 84px;
  }

  .contact-us .heading-about {
    margin-bottom: 10% !important;
  }

  .contact-us .form-holder .form-label {
    font-size: 14px;
  }

  .contact-us .address-holder h4 {
    font-size: 12px;
    color: #000;
    margin-top: 9px;
  }

  .contact-us .form-holder .btn-dark {
    font-size: 14px;
    width: 45%;
    height: 40px;
  }

  .Contact-PopUp .modal-dialog .modal-content .modal-body h1.successfully {

    font-size: 17px;
  }

  .Contact-PopUp .modal-dialog .modal-content .modal-body h1.Thanks {
    font-size: 20px;

  }

  .Contact-PopUp .modal-dialog .modal-content .modal-body .circle {
    left: 33%;
    width: 124px;
    height: 124px;
    top: -84px
  }

  .contact-us .border-red {
    width: 58px;
  }
}

@media(min-width:360px) and (max-width:421px) {
  .contact-us {
    margin-bottom: 4%;
  }
}

@media(min-width:0px) and (max-width:359px) {
  .contact-us {
    margin-bottom: -3%;
  }

  .Contact-PopUp .modal-dialog .modal-content .modal-body .circle {
    left: 30%;
    width: 115px;
    height: 115px;
    top: -78px;

  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .contact-us .fa-icon {
    color: #fff;
  }

  .desktopview-contactus {
    display: none;
  }

  .mobileview-contactus {
    display: block;
  }

  .contact-us {
    background-image: none;
    margin-bottom: 12%;
  }

  .contact-us .loate-holder {
    position: relative;
    margin-top: 5%;
  }

  .contact-us .circle-holder {
    width: 31px;
    height: 31px;
    background: #000;
    border-radius: 100%;
  }

  .contact-us .form-holder {
    margin-top: 20%;
    position: unset;
    width: 100%;

  }

  .contact-us .imag-overlay {
    display: none;
  }

  .contact-us .address-holder h4 {
    font-size: 14px;
    color: #000;
  }

  .contact-us .contact-sec {
    margin-bottom: 4%;
  }

  .contact-us .heading-about h4 {
    font-size: 18px;
  }

  .contact-us .border-line {
    width: 52px;
  }

  .contact-us .heading-about {
    margin-bottom: 10% !important;
  }

  .contact-us .form-holder .form-label {
    font-size: 14px;
  }

  .contact-us .address-holder h4 {
    font-size: 12px;
  }

  .contact-us .form-holder .btn-dark {
    font-size: 14px;
    width: 45%;
    height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-us .form-holder {
    top: 14%;
    width: 332px;
    padding: 28px;
  }

  .contact-us .address-holder h4 {
    font-size: 16px;
    color: #fff;
  }

  .contact-us {
    height: 579px;
  }

  .contact-us .loate-holder {

    margin-left: 0%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .contact-us .form-holder {
    top: 20%;
    width: 412px;
    padding: 28px;
  }

}

@media (min-width: 1200px) and (max-width: 1400px) {
  .contact-us .form-holder {
    top: 20%;
    width: 501px;
    padding: 28px;
  }

}