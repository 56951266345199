.application {
  margin-top: 5%;
  margin-bottom: 5%;
}

.application .heading-about h4 {
  font-size: 29px;
  font-weight: bold;
}

.application .border-red {
  background: #ed1b24;
  height: 3px;
  width: 63px;
}

.application .border-line {
  height: 1px;
  background: #000000;
  width: 107px;
  margin-top: 1px;
}

.application .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000066;
  border-radius: 0%;
  border: none;
  padding: 10px;
  position: relative;
  margin-top: 30%;
  min-height: 232px;
  height: 70%;
  transition: box-shadow 0.3s ease-in-out;
}

.application .card:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #ed1b2466;
}

.application .card .mission {
  width: 102px;
  height: 102px;
  position: absolute;
  top: -27%;
  z-index: 1;
  left: 0%;
  right: 0%;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  background-color: #e5e5e5;
  border-radius: 100%;
}

.application .card:hover .mission {
  background-color: #fff;
  border-radius: 0%;
  /* top: -30%; */
}

.application .commercial-icon {
  width: 68px;
  height: 68px;
  transition: 0.8s;
}

.application .card:hover .commercial-icon {
  width: 96px;
  height: 96px;
  filter: invert(16%) sepia(77%) saturate(6294%) brightness(97%) contrast(92%);
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out,
    filter 0.4s ease-in-out;
}

.application .heading-text h4 {
  font-size: 20px;
  margin-top: 20%;
}

.application .card:hover .heading-text h4 {
  font-weight: bold;
}

.application .text-holder p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


@media (min-width: 0px) and (max-width: 320px) {
  .application {
    margin-top: 16%;
  }

  .application .heading-about h4 {
    font-size: 18px;
  }

  .application .heading-text h4 {
    font-size: 16px;
  }

  .application .text-holder p {
    font-size: 12px;
  }

  .application .card {
    margin-top: 20%;
  }

  .application .border-line {
    width: 60px;
  }

  .application .border-red {
    width: 38px;
  }
}

@media (min-width: 321px) and (max-width: 576px) {
  .application {
    margin-top: 16%;
  }

  .application .heading-about h4 {
    font-size: 18px;
  }

  .application .border-line {
    width: 49px;
  }

  .application .heading-text h4 {
    font-size: 16px;
  }

  .application .text-holder p {
    font-size: 12px;
  }

  .application .card {
    margin-top: 23%;
  }

  .application .border-line {
    width: 60px;
  }

  .application .border-red {
    width: 40px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .application {
    margin-top: 9%;
  }

  .application .heading-about h4 {
    font-size: 18px;
  }

  .application .border-line {
    width: 49px;
  }

  .application .heading-text h4 {
    font-size: 16px;
  }

  .application .text-holder p {
    font-size: 12px;
  }

  .application .border-line {
    width: 60px;
  }

  .application .border-red {
    width: 47px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .application {
    margin-top: 8%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .application {
    margin-top: 8%;
  }

  .application .card .mission {
    /* top: -21%;

        left: 38%; */
  }
}
