.NewsDetailspage .details-img-class {
        width: 100%;
}

.NewsDetailspage  p {
    text-align: justify;
    font-size: 14px;
} 

.NewsDetailspage  h2 {
    font-size: 50px;
    font-weight: bold;
} 


/* Media Query */

@media(min-width:0px) and (max-width:320px) {
    .NewsDetailspage  h2 {
        font-size: 20px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .NewsDetailspage  h2 {
        font-size: 25px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .NewsDetailspage  h2 {
        font-size: 25px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .NewsDetailspage  h2 {
        font-size: 30px;
    }
}

@media(min-width:769px) and (max-width:992px) {
    .NewsDetailspage  h2 {
        font-size: 30px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .NewsDetailspage  h2 {
        font-size: 30px;
    }
}