.product-vertical-line {
    border-right: 1px solid black;
}


.header-main {
    padding-top: 15px;
    position: sticky;
    top: 0px;
    z-index: 10;
    background-color: #C9C9C9;
    height: 27px;
}

.header-main.scrolled {
    background-color: #FFF;
    height: auto;
}

.header-main .navbar .menus-main {
    background-color: #FFF;
    padding: 0px;
    box-shadow: 0px 3px 6px #00000029;
    justify-content: space-around;
}

.header-main .navbar .navbar-nav {
    align-items: center;
}

.header-main .navbar .headlogo {
    width: 100px;
    height: auto;
    transform: scale(1.5);
    background: white;
    padding: 5px;
    margin-left: 17px;
    box-shadow: 0px 3px 6px #0000008A;
}

.header-main .navbar .navbar-nav a.active span {
    padding: 0px 0px 17px 0px;
    width: fit-content;
    border-bottom: 4px solid red;
    border-radius: 2px;
}

/* .header-main .navbar .navbar-nav a{
    margin-left: 50px;
} */
.header-main a {
    color: #000;
}

.header-main .navbar .navbar-nav .nav-link {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    margin: 0px 13px;
    padding: 17px;
}

.header-main .navbar .drop-main-heading .nav-link {
    font-size: 15px;
    color: #000;
    font-weight: 600;
    margin: 0px 0px;
    padding: 3px;
    border-bottom: 2px solid red;
    width: -moz-fit-content;
    width: fit-content;
}



.header-main .navbar .navbar-nav a.active {
    width: fit-content !important;

    color: #000 !important;
    font-weight: bold;
    /* border-bottom: 3px solid #ed1b24; */
    border-radius: 2px;
}

.header-main .navbar .contact-btnn {
    background: #ed1b24;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    border-radius: 0px;
    /* margin-right: 28px; */
}

.header-main .navbar .contact-btnn:focus {
    background: #ed1b24;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    border-radius: 0px;
    /* margin-right: 28px; */
}

.header-main .dropdown-toggle::after {
    display: none;

}

.header-main .dropdown-menu {
    width: 1274px;
    /* height: 444px; */
    background: #F6F6F6;
    box-shadow: 0px 3px 6px #ED1B2466;
    margin: auto !important;
    position: absolute;
    left: 2%;
    transform: translateX(-36%);
    padding: 20px 16px 22px 23px;
    top: 125%;
    /* z-index: -1; */
}

.header-main .dropdown-item {
    white-space: normal !important;
}

/* .header-main .dropdown-item  a{
    line-break: anywhere;
} */
.header-main .dropdown-menu .drop-main-heading {
    padding: 4px;
    color: #333;
    text-decoration: none;
    display: block;
    margin: 0px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000045;

}

.header-main .dropdown-menu .inner-link {
    padding: 0px !important;
}

.header-main .dropdown-menu a:hover {
    background-color: transparent !important;
    color: #000;
}

.header-main .dropdown-menu .dropdown-item span {
    font-size: 14px;
    font-weight: bold;


}

.header-main .dropdown-menu .dropdown-item .border-line {
    border-bottom: 2px solid #ed1b24;
    width: 124px;
}

.header-main .dropdown-menu .dropdown-item .border-line1 {
    border-bottom: 2px solid #ed1b24;
    width: 176px;
}

.header-main .dropdown-menu .dropdown-item .border-line2 {
    border-bottom: 2px solid #ed1b24;
    width: 117px;
}



.header-main .navbar-nav .nav-item .dropdown-menu .content-name {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5%;
    margin-bottom: 4%;
    padding: 0px 0px 0px 14px;
}

.header-main .navbar-nav .vl {
    border-left: 1px solid #707070;
    height: 288px;
    position: absolute;
    margin-left: 0px;
    top: 23%;
    left: 17%;
}

.header-main .navbar-nav .dropdown-menu {
    position: absolute;
    top: 72px !important;
}

.header-main .navbar-nav .vl1 {
    border-left: 1px solid #707070;
    height: 288px;
    position: absolute;
    margin-left: 0px;
    top: 22%;
    left: 34%;
}

.header-main .navbar-nav .vl2 {
    border-left: 1px solid #707070;
    height: 288px;
    position: absolute;
    margin-left: 0px;
    top: 22%;
    left: 50%;
}

.header-main .navbar-nav .vl3 {
    border-left: 1px solid #707070;
    height: 288px;
    position: absolute;
    margin-left: 0px;
    top: 22%;
    left: 67%;
}

.header-main .navbar-nav .vl4 {
    border-left: 1px solid #707070;
    height: 288px;
    position: absolute;
    margin-left: 0px;
    top: 22%;
    left: 83%;
}

.header-main .navbar-nav hr {
    border: 1px solid #000;
    width: 93%;
    margin-left: 3%;
}

.menu-off-canva {
    width: 100% !important;
}

.menu-off-canva .offcanvas-body {
    padding: 0px;
}

/* Optional: Center the dropdown arrow */
/* .header-main .navbar-nav .nav-item .dropdown-toggle::after {
    margin-left: 0.5em;
    vertical-align: middle;
} */

.menu-off-canva .offcanvas-body .sec-3 .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.menu-off-canva .offcanvas-body .sec-1 {
    background: #ff0000;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
}

.menu-off-canva .offcanvas-body .sec-2 {
    background: #ffd7db;
    width: 100%;
    height: 100vh;
}

.menu-off-canva .offcanvas-body .sec-3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}

.menu-off-canva .offcanvas-body .sec-3 .off-nav-mennn {
    margin: 20px 0px;
    font-size: 25px;
    position: relative;
}

.menu-off-canva .offcanvas-body .sec-3 .off-nav-mennn::after {
    content: "";
    position: absolute;
    border-bottom: 5px solid #ff0000;
    width: 30px;
    left: 75px;
    top: 48px;
}

.menu-off-canva .offcanvas-body .sec-3 .extra-menus-div {}

@media (min-width:0px) and (max-width:767.98px) {
    .header-main .navbar .navbar-toggler {
        background-color: white;
        border-radius: 0px;
        font-size: 15px;
    }

    .header-main .navbar .navbar-nav {
        margin-left: 0px;
        margin-top: 15px;
    }

    .header-main .navbar .headlogo {
        width: 65px;
    }

    .header-main .navbar {
        padding: 5px 5px 5px 15px;
    }

    .header-main {
        padding-top: 0px;
    }

    .header-main .navbar .navbar-nav .nav-link {
        font-size: 14px;
        margin: 0px 0px;
    }

    .header-main .navbar .contact-btnn {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
    }

    .header-main .navbar .navbar-collapse {
        padding-bottom: 15px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .header-main .navbar .navbar-nav .nav-link {
        font-size: 11px;
        margin: 3px 1px;
        padding: 6px;
    }

    .header-main .navbar .contact-btnn {
        font-size: 12px;
    }

    .header-main .navbar .contact-btnn:focus {
        font-size: 12px;
    }

    .header-main .navbar .headlogo {
        width: 70px;
    }

    .header-main {
        padding-top: 11px;
    }

    .header-main .navbar .navbar-nav a.active span {
        padding: 0px 0px 7px 0px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item .border-line2 {
        width: 105px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item .border-line1 {
        width: 164px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item .border-line {
        width: 114px;
    }
}

@media (min-width:992px) and (max-width:1400px) {


    .header-main .navbar .contact-btnn {
        font-size: 14px;
    }

    .header-main .navbar .contact-btnn:focus {
        font-size: 14px;
    }

    .header-main .navbar .headlogo {
        width: 90px;
    }

    .header-main {
        padding-top: 11px;
    }

    .header-main .navbar .navbar-nav a.active span {
        padding: 0px 0px 11px 0px;
    }



}

.mobile-view-header {
    display: none;
}

@media(min-width:1200px) and (max-width:1400px) {
    .header-main .navbar-nav .nav-item .dropdown-menu {
        width: 1113px;
        left: 52%;
        transform: translateX(-34%);
        top: 141%;
    }

    .header-main .navbar .navbar-nav .nav-link {
        font-size: 14px;
        margin: 0px 10px;
        padding: 12px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item span {
        font-size: 12px;

    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item .border-line1 {
        border-bottom: 2px solid #ed1b24;
        width: 151px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item .border-line2 {
        width: 100px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .header-main .navbar .navbar-nav .nav-link {
        font-size: 13px;
        margin: 8px 7px;
        padding: 6px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu {
        width: 904px;
        left: 20%;
        top: 134%;
    }

    .header-main .navbar-nav hr {
        border: 1px solid #000;
        display: none;
    }

    .header-main .navbar-nav .vl {
        height: 360px;
        left: 229px;
        top: 16%;
    }

    .header-main .navbar-nav .vl1 {
        display: none;
    }

    .header-main .navbar-nav .vl4 {
        display: none;
    }

    .header-main .navbar-nav .vl3 {
        height: 360px;
        top: 16%;
        left: 75%;
    }

    .header-main .navbar-nav .vl2 {
        height: 360px;
        top: 16%;
    }

    .header-main .scroller {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100px;
        min-height: 65vh;
    }

    .header-main .scroller::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .header-main .scroller::-webkit-scrollbar-track {
        background: #E8E8E8;
    }

    /* Handle */
    .header-main .scroller::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 5px;
    }




}

@media(min-width:768px) and (max-width:991px) {
    .header-main .navbar-nav .nav-item .dropdown-menu {
        width: 656px;
        left: 31%;
        top: 134%;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .dropdown-item span {
        font-size: 13px;
    }

    .header-main .navbar-nav .nav-item .dropdown-menu .content-name {
        font-size: 11px;

    }

    .header-main .navbar-nav hr {
        border: 1px solid #000;
        display: none;
    }

    .header-main .navbar-nav .vl {
        height: 447px;
        left: 224px;
        top: 13%;
    }

    .header-main .navbar-nav .vl1 {
        display: none;
    }

    .header-main .navbar-nav .vl4 {
        display: none;
    }

    .header-main .navbar-nav .vl3 {
        height: 447px;
        top: 13%;
        left: 66%;
    }

    .header-main .navbar-nav .vl2 {
        display: none;
    }

    .header-main .scroller {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100px;
        min-height: 80vh;
    }

    .header-main .scroller::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .header-main .scroller::-webkit-scrollbar-track {
        background: #E8E8E8;
    }

    /* Handle */
    .header-main .scroller::-webkit-scrollbar-thumb {
        background: #707070;
        border-radius: 5px;
    }

    .header-main {
        background-color: #fff;
    }
}

@media(min-width:0px) and (max-width:1024px) {
    .header-main {
        display: none;
    }

    .mobile-view-header {
        display: block;
        background: #ffffff;
        position: sticky;
        z-index: 999999;
        top: 0%;
        box-shadow: 0pt 3pt 6pt #00000070;
    }

    .mobile-view-header .contact-btnn {
        background: #ed1b24;
        color: #fff;
        border: none;
        font-size: 14px;
        border-radius: 0px;

    }

    .mobile-view-header .contact-btnn:hover {
        background: #ed1b24 !important;
        color: #fff !important;
        font-size: 14px;
        border-radius: 0px;
        border: none;
    }

    .mobile-view-header .contact-btnn:focus {
        background: #ed1b24 !important;
        color: #fff !important;
        font-size: 14px;
        border-radius: 0px;
        border: none;
    }

    .mobile-app-offcanvas .headlogo {
        width: 60px;
        height: auto;
        transform: scale(1.5);
        background: white;
        /* padding: 5px; */
        margin-left: 14px;
        box-shadow: 0px 3px 6px #0000008A;
    }
}


@media (min-width:1401px) and (max-width:1600px) {
    .header-main .navbar-nav .nav-item .dropdown-menu {
        left: 50%;
    }
}


.mobile-app-offcanvas .offcanvas.offcanvas-start {
    z-index: 9999999999;
    width: 260px;
    border-radius: 0pt 15pt 15pt 0pt;
}

.mobile-app-offcanvas .offcanvas-backdrop {
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.6);
    opacity: 1.5;
    z-index: 999999999;
}

.mobile-app-offcanvas a {
    text-decoration: none;
}

.mobile-app-offcanvas .all-pages .page-holder p {
    font-size: 15px;
    color: #000;
    font-weight: bold !important;
}

.mobile-app-offcanvas .offcanvas .offcanvas-header .btn-close {
    margin-left: auto;
}


.mobile-app-offcanvas .accordion-button {
    border-radius: 0% !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 15px;
    color: #000;
    padding: 0px;
    margin-bottom: 12px;
    font-weight: bold;
}

.mobile-app-offcanvas .accordion-item .drop-main-heading .accordion-button {
    font-size: 14px !important;
    font-weight: normal !important;
}

.mobile-app-offcanvas .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 0%;
}

.mobile-app-offcanvas .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.mobile-app-offcanvas .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    border-radius: 0% !important;
    border: none;
    box-shadow: none !important;
    color: #000;
}

.mobile-app-offcanvas .accordion-body {
    padding: 0px !important;
}

.mobile-app-offcanvas .accordion-item {
    border-radius: 0% !important;
    border: none !important;
    box-shadow: none !important;

}

.mobile-app-offcanvas .accordion-item .content-name {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5%;
    margin-bottom: 4%;
    color: #000;
}