.become {
    margin: 6% 0;
}

.become .bg-image {
    background-image: url('../../../public/assets/images/product-home/cube-computing.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 20px;
    position: relative;
    padding: 80px 45px 56px 45px;
}

.become .imag-overlay {
    position: absolute;
    border-radius: 20px;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #0000009e, #00000059);
    /* opacity: 0.59; */
}

.become .partner-heading h4 {
    font-size: 40px;
    font-weight: bold;
    color: #FFF;
    position: relative;
}

.become .partner-heading p {
    font-size: 16px;
    color: #FFF;
    position: relative;
}

.become .total-holder h4 {
    font-size: 50px;
    color: #FFF;
    position: relative;
}

.become .total-holder p {
    font-size: 18px;
    color: #FFF;
    position: relative;
}


@media(min-width:0px) and (max-width:359px) {
    .become .total-holder h4 {
        font-size: 25px;
        margin-top: 3%;
    }

    .become .total-holder p {
        font-size: 13px;
        margin-top: 3%;
    }

    .become .partner-heading h4 {
        font-size: 23px;
        text-align: center;
    }

    .become .partner-heading p {
        font-size: 13px;
        text-align: center;
    }
}

@media(min-width:360px) and (max-width:567px) {
    .become .total-holder h4 {
        font-size: 25px;
        margin-top: 3%;
    }

    .become .total-holder p {
        font-size: 13px;
        margin-top: 3%;
    }

    .become .partner-heading h4 {
        font-size: 27px;
        text-align: center;
    }

    .become .partner-heading p {
        font-size: 13px;
        text-align: center;
    }
}

@media(min-width:567px) and (max-width:767px) {
    .become .total-holder h4 {
        font-size: 22px;
        margin-top: 3%;
    }

    .become .total-holder p {
        font-size: 12px;
        margin-top: 3%;
    }

    .become .partner-heading h4 {
        font-size: 27px;
        text-align: center;
    }

    .become .partner-heading p {
        font-size: 13px;
        text-align: center;
    }
}


@media(min-width:768px) and (max-width:991px) {
    .become .total-holder h4 {
        font-size: 23px;
    }

    .become .total-holder p {
        font-size: 12px;
    }

    .become .partner-heading h4 {
        font-size: 27px;

    }

    .become .partner-heading p {
        font-size: 13px;
    }
}


@media(min-width:992px) and (max-width:1199px) {
    .become .total-holder h4 {
        font-size: 33px;
    }

    .become .total-holder p {
        font-size: 14px;
    }

    .become .partner-heading h4 {
        font-size: 32px;

    }
}





@media(min-width:1201px) and (max-width:1401px) {
    .become .total-holder h4 {
        font-size: 33px;
    }

    .become .total-holder p {
        font-size: 14px;
    }

    .become .partner-heading h4 {
        font-size: 36px;

    }
}