.home-product-section {
    margin: 2% 0;
}

.home-product-section .heading-holder h4 {
    font-size: 50px;
    font-weight: bold;
    color: #000000;
}

.home-product-section .heading-holder p {
    letter-spacing: 0.32px;
    font-size: 16px;
    margin: auto;
    color: #00000096;
}

.home-product-section .card {
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 13px;
    height: 360px;
    transition: filter .6s, opacity .7s, transform .6s, box-shadow .3s, -webkit-filter .6s, -webkit-transform .6s;
}

.home-product-section .card:hover {
    /* background: #FFF 0% 0% no-repeat padding-box; */
    /* box-shadow: 7px 0px 26px #0000000D; */
    border-color: transparent;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.11);
}

.home-product-section .card .card-body h5 {
    font-size: 22px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #000;
}

.home-product-section .card .card-body {
    text-align: center;
}

.home-product-section .card .card-body .get-details {
    font-size: 16px;
    margin-bottom: 0px;
    text-align: center;
    transition: 0.2s;
    color: #000;
    border: 0;
    background: transparent;
}

.home-product-section .card .card-body .card-text {
    margin-top: 25px;
}

.home-product-section .card:hover .get-details {
    font-size: 20px;
    text-align: center;
    /* transition: 0.4s; */
    transition: 0.1s;
}

.home-product-section .card .product-icon {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    aspect-ratio: 1/1;
    background-position: center;
    margin-top: 31px;
}

.home-product-section .card .product-icon .product-img {
    width: 181px;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
}

.home-product-section .swiper-button-prev {
    display: none;
}

.home-product-section .swiper-button-next {
    display: none;
}


.home-product-section .swiper-pagination-bullet {
    width: 21px;
    height: 6px;
    border-radius: 5px;
}

.home-product-section .swiper-pagination-bullet-active {
    width: 41px;
    background: #f00;
}

.home-product-section .swiper-pagination {
    bottom: 0px;
}

.home-product-section .swiper {
    padding-bottom: 40px;
}


@media(min-width:0px) and (max-width:767px) {
    .home-product-section .heading-holder h4 {
        font-size: 30px;

    }

    .home-product-section .heading-holder p {
        font-size: 14px;

    }

    /* .home-product-section .swiper-pagination-bullet {
        width: 13px;
    } */
    .home-product-section .swiper-pagination-bullet {
        width: 13px;

    }

    .home-product-section .swiper-pagination-bullet-active {
        width: 20px;

    }
}

@media (min-width:768px) and (max-width:991px) {
    .home-product-section .card .card-body h5 {
        font-size: 14px;
    }

    .home-product-section .card .card-body .get-details {
        font-size: 12px;
    }

    .home-product-section .card .card-body .card-text {
        margin-top: 10px;
    }

    .home-product-section .card .product-icon .product-img {
        width: 100px;
    }

    .home-product-section .card .product-icon {
        width: 100%;
        height: 150px;
    }

    .home-product-section .card {
        height: 250px;
    }

    .home-product-section .heading-holder h4 {
        font-size: 36px;
    }

    .home-product-section .heading-holder p {
        font-size: 12px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .home-product-section .card .card-body h5 {
        font-size: 18px;
    }

    .home-product-section .card .card-body .get-details {
        font-size: 14px;
    }

    .home-product-section .card .card-body .card-text {
        margin-top: 10px;
    }

    .home-product-section .card .product-icon .product-img {
        width: 100px;
    }

    .home-product-section .card .product-icon {
        width: 100%;
        height: 150px;
    }

    .home-product-section .card {
        height: 260px;
    }

    .home-product-section .heading-holder h4 {
        font-size: 42px;
    }

    .home-product-section .heading-holder p {
        font-size: 12px;
    }
}

@media (min-width:1201px) and (max-width:1400px) {
    .home-product-section .card .card-body h5 {
        font-size: 20px;
    }

    .home-product-section .card .card-body .get-details {
        font-size: 14px;
    }

    .home-product-section .heading-holder h4 {
        font-size: 40px;
    }

    .home-product-section .heading-holder p {
        font-size: 14px;
    }
}