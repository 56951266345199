.cctv-innerpage {
  margin-top: 10%;
}

.cctv-innerpage .IR-dome-section .red-line {
  border-top: 1px solid #ed1b24;
  width: 100%;
}

.cctv-innerpage .cards-section .cards .security {
  margin-top: -10px;
}

.cctv-innerpage .IR-heading p {
  color: #000000;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
  font-family: "Open Sans";
  line-break: anywhere;
}

.cctv-innerpage .IR-dome-section {
  margin: 0px 3%;
}

.cctv-innerpage .IR-dome-section .description {
  margin: 2% 7%;
}

.cctv-innerpage .description p {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  font-family: 'Source Sans Pro';
}

.cctv-innerpage .cards-section .type-text-sec {
  height: 57px;
  background-color: #e5e5e5;
  text-align: center;
  padding: 10px 0px;
}

.cctv-innerpage .cards-section .type-text-sec p {
  font-size: 22px;
}

.cctv-innerpage .cards-section .cards {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  opacity: 1;
  min-height: 281px;
  height: 100%;
}

.cctv-innerpage .cards-section {
  margin: 35px 20px;
}

.cctv-innerpage .cards-section .inner-card {
  padding: 9% 0% 5% 20%;
}

.cctv-innerpage .cards-section .inner-card .boxes {
  background-color: #ed1b24;
  height: 6px;
  width: 6px;
  left: 11%;
}

.cctv-innerpage .cards-section .inner-card .black-line {
  width: 1.5px;
  height: 41px;
  background-color: #bdbdbd;
  margin: 0px 2px;
}

.cctv-innerpage .cards-section .inner-card p {
  font-size: 20px;
  color: #707070;
}

.cctv-innerpage .circle-prev {
  background-color: #ffefef;
  color: #000;
  width: 28px;
  height: 28px;
  border-radius: 100%;
}

.cctv-innerpage .circle-prev .fa-previcon {
  margin-top: 6px;
  color: #000;
}

.cctv-innerpage .circle-prev:hover .fa-previcon {
  cursor: pointer;
  color: #ed1b24;
}

.cctv-innerpage .cards-section .inner-card .onsite {
  margin-top: -6px;
}

.cctv-innerpage .cards-section .inner-card .onsite .scale {
  padding: 3px 0px;
}

.cctv-innerpage .card-sec {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  height: auto;
  margin-left: auto;
}

.cctv-innerpage .card-sec:hover .product-img {
  transform: scale(1.4);
}

.cctv-innerpage .card-sec .product-detail {
  width: 100%;
  height: 343px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  transition: transform 0.5s;
}

.cctv-innerpage .card-sec .product-detail img {
  transition: transform 0.5s;
}

.cctv-innerpage .card-sec:hover .product-detail img {
  transform: scale(1.2);

}

.cctv-innerpage .card-sec .product-img {
  width: 213px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
}

.cctv-innerpage .mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0px 35px 0px;
  margin-left: 2%;
}

.cctv-innerpage .leftarrowIcon {
  background: #FFEFEF 0% 0% no-repeat padding-box;
  border-radius: 50%;
  /* padding: 6px; */
  /* line-height: 15px; */
  width: 28px;
  height: 28px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.cctv-innerpage .leftarrowIcon .leftchevronIcon {}

.cctv-innerpage .camera-imgs {
  width: 159px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000078;
  height: 127px;
  min-width: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  padding: 3% 8px;
  margin-left: 5px;
}

.cctv-innerpage .camera-imgs .inner-img {
  width: 91px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.cctv-innerpage .main-slider-cctv {
  position: relative;
}

.cctv-innerpage .main-slider-cctv .arrow-back,
.main-slider-cctv .arrow-next {
  position: absolute;
  bottom: -80px;
  left: 33px;
  right: 83px;
  cursor: pointer;
}

.cctv-innerpage .main-slider-cctv .arrow-back::after {
  content: url("../../../public/assets/images/icon/arrow-back.png");
  position: absolute;
  top: 43%;
  left: -7px;
}

.cctv-innerpage .main-slider-cctv .arrow-next::before {
  content: url("../../../public/assets/images/icon/arrow-next.png");
  position: absolute;
  top: 43%;
  right: -7px;
}

.cctv-innerpage .swiper-button-prev {
  left: 0%;
  position: absolute;
  display: none;
}

.cctv-innerpage .swiper-button-next {
  right: 0%;
  position: absolute;
  display: none;
}

.cctv-innerpage .IR-hover-sec:hover {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.cctv-innerpage .IR-hover-sec {
  padding: 10px 0px 10px 35px;
  margin-right: 5%;
}

.cctv-innerpage .cards-section .cards .surv-text {
  margin-top: 16px;
}

/* ***********media query start ***************** */
@media (min-width:0px) and (max-width:320px) {
  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 150px;
  }

  .cctv-innerpage {
    margin-top: 23%;
  }

  .cctv-innerpage .IR-heading p {
    font-size: 22px;
  }

  .cctv-innerpage .description p {
    text-align: justify;
    font-size: 14px;
  }

  .cctv-innerpage .mySwiper {
    padding: 10px 0px 11px 0px;
  }

  /* .cctv-innerpage .camera-imgs {
background: none;
} */

  .cctv-innerpage .cards-section .type-text-sec p {
    font-size: 18px;
  }

  .cctv-innerpage .cards-section .inner-card p {
    font-size: 16px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .camera-imgs {
    margin: 10px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next::before {

    right: 17px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 35px;
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -6px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .surv-text {
    margin-top: 11px;
  }
}


@media (min-width:321px) and (max-width:485px) {
  .cctv-innerpage .card-sec .product-detail {
    height: auto;
  }

  .cctv-innerpage .IR-heading p {
    font-size: 23px;
  }

  .cctv-innerpage .description p {
    text-align: justify;
    font-size: 15px;
  }

  .cctv-innerpage .mySwiper {
    padding: 15px 0px 15px 0px;
  }

  .cctv-innerpage .cards-section .type-text-sec p {
    font-size: 19px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 9% 0% 5% 26%;
  }

  .cctv-innerpage .cards-section .inner-card p {
    font-size: 16px;

  }

  .cctv-innerpage .camera-imgs {
    margin: 10px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 16%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-next::before {
    right: 0px;
  }

  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 160px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 34px;
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -9px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .security {
    margin-top: -9px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }

  .cctv-innerpage .cards-section .cards .surv-text {
    margin-top: 14px;
  }
}

@media (min-width:486px) and (max-width:575px) {
  .cctv-innerpage .card-sec .product-detail {
    height: auto;
  }

  .cctv-innerpage .IR-heading p {
    font-size: 24px;
  }

  .cctv-innerpage .description p {
    font-size: 16px;
    text-align: justify;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 4% 0% 5% 25%;
  }

  .cctv-innerpage .cards-section .type-text-sec p {
    font-size: 20px;
  }

  .cctv-innerpage .cards-section .inner-card p {
    font-size: 17px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 16%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 165px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {
    height: 39px;
  }

  .cctv-innerpage .card-sec:hover .product-img {
    transform: scale(1.2);
  }
}

@media (min-width:576px) and (max-width:767px) {
  .cctv-innerpage .card-sec .product-detail {
    height: auto;
  }

  .cctv-innerpage .IR-heading p {
    font-size: 24px;
  }

  .cctv-innerpage .description p {
    font-size: 16px;
    text-align: justify;
  }

  .cctv-innerpage .cards-section .inner-card {
    padding: 6% 0% 5% 25%;
  }

  .cctv-innerpage .cards-section .type-text-sec p {
    font-size: 20px;
  }

  .cctv-innerpage .cards-section .inner-card p {
    font-size: 18px;
  }

  .cctv-innerpage .cards-section .cards {
    min-height: 0px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage {
    margin-top: 13%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin: 3% 2% 1% 6%;
    padding: 10px 10px 10px 20px;
  }

  .cctv-innerpage .main-slider-cctv .arrow-back::after {
    left: -32px;
  }

  .cctv-innerpage .card-sec .product-detail {
    height: 250px;
  }

  .cctv-innerpage .card-sec .product-img {
    width: 165px;
  }

  .cctv-innerpage .cards-section .inner-card .black-line {

    height: 40px;

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .cctv-innerpage .cards-section .inner-card {
    padding: 9% 0% 5% 10%;
  }

  .cctv-innerpage .IR-hover-sec {
    margin-top: 4%;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }

  .cctv-innerpage .description p {

    font-size: 16px;

  }
}

@media (min-width: 992px) and (max-width: 1200px) {


  .cctv-innerpage .description p {
    margin-bottom: 0%;
    font-size: 16px;
  }

  .cctv-innerpage .camera-imgs .inner-img {
    width: 84px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .cctv-innerpage .camera-imgs .inner-img {
    width: 82px;
  }

  .cctv-innerpage .card-sec {
    margin: auto;
  }
}