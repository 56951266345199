.enquiry {
  margin: 4% 0;
  background-color: #fbfbfb;
  padding: 40px 0px;
}

.enquiry .enquiry-heading h5 {
  font-size: 50px;
  font-weight: bold;
  color: #000000;
}

.enquiry .enquiry-heading p {
  font-size: 16px;
  font-weight: 300;
  max-width: 260px;
}

.enquiry .form-label {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.enquiry .form-control {
  border-radius: 0%;
  background: #f1f1f1;
  color: #848484;
  font-size: 13px;
  height: 40px;
  border: none;
}

.enquiry .form-control:focus {
  border-radius: 0%;
  background: #f1f1f1;
  color: #848484;
  font-size: 13px;

  border: none;
}

.enquiry .textarea-holder {
  border-radius: 0%;
  background: #f1f1f1;
  color: #848484;
  font-size: 13px;
  border: none;
  height: auto;
}

.enquiry .form-control::placeholder {
  color: #848484;
  font-size: 13px;
}

.enquiry .btn-submit {
  width: 119px;
  height: 40px;
  background: #000000;
  color: #fff;
  font-size: 14px;
  border-radius: 0%;
  box-shadow: #000;
}

.enquiry .btn-submit:hover {
  color: #ed1b24;
  width: 119px;
  height: 40px;
  background: #000000;
  font-size: 14px;
  border-radius: 0%;
  box-shadow: #000;
}

/* *********************modal css ********************************** */

.Home-PopUp .modal-dialog .modal-content .modal-body .circle {
  left: 30%;
  display: flex;
  top: -51px;
  position: absolute;
  border-radius: 24px;
  background-color: white;
  width: 233px;
  height: 76px;
  box-shadow: 0px 3px 9px #ed1b2463;
}

.Home-PopUp .modal-dialog .modal-content .modal-header {
  border-bottom: none !important;
}

.Home-PopUp .modal-dialog .modal-content .modal-body {
  height: 165px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #ed1b2463;
  border-radius: 25px;
}

.Home-PopUp .modal-dialog .modal-content .modal-body h1.successfully {
  padding: 48px 0px 0px 0px;
  text-align: center;
  color: #000000;
  font-size: 22px;
}

.Home-PopUp .modal-dialog .modal-content .modal-body h1.Thanks {
  font-size: 25px;
  color: #ed1b24;
  text-align: center;
  font-weight: bold;
}

.Home-PopUp .modal-body {
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 9px #ed1b2463;
  border-radius: 25px;
}

.Home-PopUp .modal {
  background-color: #000;
}

.Home-PopUp .modal-dialog .modal-content {
  border-radius: 25px;
}

@media (min-width: 0px) and (max-width: 359px) {
  .Home-PopUp .modal-dialog .modal-content .modal-body .circle {
    left: 22%;
    top: -28px;
    width: 167px;
    height: 57px;

  }
}

@media (min-width: 360px) and (max-width: 420px) {
  .Home-PopUp .modal-dialog .modal-content .modal-body .circle {
    left: 27%;
    top: -28px;
    width: 167px;
    height: 57px;

  }
}

@media (min-width: 420px) and (max-width: 567px) {
  .Home-PopUp .modal-dialog .modal-content .modal-body .circle {
    left: 29%;
    top: -28px;
    width: 167px;
    height: 57px;

  }
}

@media (min-width: 567px) and (max-width: 767.98px) {
  .Home-PopUp .modal-dialog .modal-content .modal-body .circle {
    left: 29%;
    top: -28px;
    width: 167px;
    height: 57px;

  }
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .enquiry .enquiry-heading p {
    font-size: 14px;
    max-width: 228px;
  }

  .enquiry .enquiry-heading h5 {
    font-size: 30px;
  }

  .enquiry .form-label {
    font-size: 14px;
  }



  .Home-PopUp .modal-dialog .modal-content .modal-body h1.successfully {
    padding: 48px 0px 0px 0px;
    text-align: center;
    color: #000000;
    font-size: 17px;
  }

  .Home-PopUp .modal-dialog .modal-content .modal-body h1.Thanks {
    font-size: 20px;

  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .enquiry .enquiry-heading p {
    font-size: 14px;
  }

  .enquiry .enquiry-heading h5 {
    font-size: 30px;
  }

  .enquiry .form-label {
    font-size: 13px;
    margin-bottom: 2px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .enquiry .enquiry-heading p {
    font-size: 14px;
  }

  .enquiry .enquiry-heading h5 {
    font-size: 35px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .enquiry .enquiry-heading p {
    font-size: 14px;
  }

  .enquiry .enquiry-heading h5 {
    font-size: 40px;
  }
}