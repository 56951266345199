.direcrtor-profile-section {
    margin: 6% 0%;
}

.direcrtor-profile-section .sec-1 .director-img {
    width: 100%;
    height: 300px;
    /* border-radius: 100%; */
    object-fit: contain;
}

.direcrtor-profile-section .sec-2 {
    /* padding-left: 50px; */
}

.direcrtor-profile-section .sec-2 h3 {
    font-size: 23px;
    margin-top: 9px;
}

.direcrtor-profile-section .profileRow {
    margin-top: 5%;
    justify-content: center;
}

.direcrtor-profile-section .sec-2 .Name {
    color: #ed1b24;
    font-weight: 700;
    font-size: 40px;
    position: relative;
}

.direcrtor-profile-section .sec-2 .Name::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 45px;
    border-bottom: 5px solid #ccc;
}

.direcrtor-profile-section .sec-2 .position {
    font-size: 25px;
    color: #000;
    margin-top: 30px;
}

.direcrtor-profile-section .sec-2 .description {
    color: #000;
    font-size: 14px;
}

.direcrtor-profile-section .description {
    width: 100% !important;
    overflow-wrap: anywhere;
}


@media (min-width:0px) and (max-width:768px) {
    .direcrtor-profile-section .sec-2 {
        padding-left: 0px;
        margin-top: 20px;
    }

    .direcrtor-profile-section .sec-2 .Name {
        font-size: 25px;
    }

    .direcrtor-profile-section .sec-2 .position {
        font-size: 20px;
        margin-top: 20px;
    }

    .direcrtor-profile-section .sec-2 .description {
        font-size: 13px;
    }


    .direcrtor-profile-section .description {
        overflow-y: auto;
        height: 228px;
        font-size: 15px;
    }


    .direcrtor-profile-section .sec-2 p {
        text-align: left;
        font-size: 14px;
    }

    .direcrtor-profile-section .sec-1 .director-img {
        width: 100%;
        height: 163px;
        /* border-radius: 100%; */
        object-fit: contain;
    }

    .direcrtor-profile-section .sec-2 h3 {
        font-size: 15px;
        margin-top: 2px;
    }
}