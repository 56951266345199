.complaint {
    background-image: url('../../public/assets/images/banner/welcome-double.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    position: relative;
}

.complaint .circle-holder {
    width: 31px;
    height: 31px;
    background: #FFFFFF;
    border-radius: 100%;
}

.complaint .circle-holder:hover {
    background: #ED1B24 0% 0% no-repeat padding-box;
    border: 1px solid #FFF;
}

.complaint .circle-holder:hover .fa-icon {
    color: #FFF;
}

.complaint .address-holder h4 {
    font-size: 20px;
    color: #FFF;
}

.complaint .loate-holder {
    top: 55%;
    transform: translate(0, -50%);
    position: absolute;
}

.complaint .contact-sec {
    margin-bottom: 15%;
}

.complaint .imag-overlay {
    position: absolute;
    background: transparent linear-gradient(178deg, #ED1B24D6 0%, #B7151CDE 0%, #150203F8 0%, #C8171F30 6%,
            #871010FA 27%, #8B3636FA 34%, #000000 66%, #010101 100%) 0% 0% no-repeat padding-box;
    opacity: 0.79;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;

}

.complaint .form-holder {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 11px #0000004A;
    border-radius: 0px;
    top: 50%;
    z-index: 1;
    position: absolute;
    width: 716px;
    padding: 29px;

    left: 50%;
    transform: translate(-50%, -50%);
}

.complaint .heading-about h4 {
    font-size: 25px;
    font-weight: bold;

}

.complaint .border-red {
    background: #ED1B24;
    height: 3px;
    width: 93px;
}

.complaint .border-line {
    height: 1px;
    background: #000000;
    width: 100px;
    margin-top: 1px;
}

.complaint .form-holder .btn-dark {
    box-shadow: 0px 3px 12px #4343433B;
    border: 1px solid #4343433B;
    border-radius: 0%;
    font-size: 18px;
    width: 81%;
    font-weight: bold;
}

.complaint .form-holder .btn-dark:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #4343433B;
    border: 1px solid #ED1B24;
    font-size: 18px;
    color: #000;
    font-weight: bold;

}

.complaint .form-holder .form-control {
    border-radius: 0%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #707070;
    border-top: none;
    border-left: none;
    border-right: none;
    opacity: 1;
    font-size: 12px;
    color: #777777;
    /* height: 48px; */
}

.complaint .form-holder .text-area {
    border-bottom: 1px solid #707070;
    border-top: none;
    border-left: none;
    border-right: none;
    opacity: 1;
    font-size: 12px;
    color: #777777;
    height: auto;
}

.complaint .form-holder .form-label {
    font-size: 14px;
    /* background: #E5E5E5 0% 0% no-repeat padding-box;
    width: 100%; */
    /* padding: 3px; */
}

.complaint .banner-icon {
    position: absolute;
    top: 56px;
    left: 46%;
    /* transform: translate(-50%, -50%); */
    margin: auto;
}

.complaint .complaint-icon {
    width: 53px;
    height: 14px;
}

.complain-modal {
    backdrop-filter: blur(1px);
    background: transparent linear-gradient(180deg, #ED1B24D6 0%, #B7151CDE 0%, #15020300 0%, #C8171F30 6%, #09010100 27%,
            #0901010d 34%, #09010100 55%, #01010100 63%, #00000000 86%, #01010100 100%) 0% 0% no-repeat padding-box;

}

.complain-modal .modal-content {

    text-align: center;
    background: #FFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #ED1B2463;
    border-radius: 25px;
    opacity: 1;
    position: relative;
}

.complain-modal .modal-content .modal-body {

    margin-top: 8%;
}

.complain-modal .modal-content .succesful-text p {
    font-size: 22px;
    font-weight: 600;
}

.complain-modal .modal-content .thank-text p {
    font-size: 25px;
    color: #ED1B24;
    font-weight: bold;
    margin: 0px;
}

.complain-modal .modal-content .circle {
    width: 125px;
    height: 125px;
    background-color: #FFF;
    border-radius: 100px;
    position: absolute;
    top: -100%;
    left: 36%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


/* media qyery start */
@media(min-width:321px) and (max-width:420px) {
    .complain-modal .modal-content .circle {
        width: 118px;
        height: 118px;
        top: -90%;
        left: 34%;

    }
}
@media(min-width:420px) and (max-width:578px) {
    .complain-modal .modal-content .circle {
        width: 118px;
        height: 118px;
        top: -109%;
        left: 36%;
    }
}
@media(min-width:0px) and (max-width:320px) {
    .complaint .form-holder {
        width: 300px;

    }

    .complaint .heading-about h4 {
        font-size: 18px;
    }

    .complaint .border-line {
        width: 62px;
    }

    .complaint .form-holder .form-label {
        font-size: 12px;
    }

    .complaint .form-holder .btn-dark {
        font-size: 14px;
        width: 45%;
    }

    .complaint .border-line {
        width: 80px;
    }

    .complaint .border-red {
        width: 60px;
    }

    .complain-modal .modal-content .succesful-text p {
        font-size: 16px;

    }

    .complain-modal .modal-content .thank-text p {
        font-size: 20px;

    }

    .complain-modal .modal-content .circle {
        width: 111px;
        height: 111px;
        top: -83%;
        left: 33%;

    }
}

@media(min-width:321px) and (max-width:576px) {
    .complaint .form-holder {
        width: 350px;

    }

    .complaint .heading-about h4 {
        font-size: 18px;
    }

    .complaint .border-line {
        width: 80px;
    }

    .complaint .border-red {
        width: 60px;
    }

    .complaint .form-holder .form-label {
        font-size: 12px;
    }

    .complaint .form-holder .btn-dark {
        font-size: 14px;
        width: 45%;
    }

    .complain-modal .modal-content .succesful-text p {
        font-size: 16px;

    }

    .complain-modal .modal-content .thank-text p {
        font-size: 20px;

    }


}

@media(min-width:577px) and (max-width:767px) {
    .complaint .form-holder {
        width: 500px;

    }

    .complaint .heading-about h4 {
        font-size: 18px;
    }

    .complaint .border-line {
        width: 80px;
    }

    .complaint .border-red {
        width: 60px;
    }

    .complaint .form-holder .form-label {
        font-size: 12px;
    }

    .complaint .form-holder .btn-dark {
        font-size: 14px;
        width: 45%;
    }

    .complain-modal .modal-content .succesful-text p {
        font-size: 16px;

    }

    .complain-modal .modal-content .thank-text p {
        font-size: 20px;

    }
}

@media(min-width:768px) and (max-width:991px) {
    .complaint .form-holder {
        width: 620px;
        left: 49%;
        padding: 15px;

    }

    .complaint .address-holder h4 {
        font-size: 14px;
        color: #FFF;
    }


    .complaint .heading-about h4 {
        font-size: 21px;

    }

    .complaint {
        height: 764px;

    }

    .complaint .banner-icon {
        position: absolute;
        top: 30px;
        left: 46%;
        margin: auto;
    }

    .complaint .border-line {
        width: 101px;
    }

    .complaint .border-red {
        width: 62px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .complaint .form-holder {

        width: 600px;
        padding: 19px;

    }

    .complaint .address-holder h4 {
        font-size: 17px;
        color: #FFF;
    }

    .complaint .banner-icon {
        position: absolute;
        top: 40px;
        left: 46%;
        /* transform: translate(-50%, -50%); */
        margin: auto;
    }

}

@media(min-width:1200px) and (max-width:1400px) {

    .complaint .banner-icon {
        position: absolute;
        top: 40px;
        left: 46%;
        /* transform: translate(-50%, -50%); */
        margin: auto;
    }
}