@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:wght@300;400;500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:wght@300;400;500&display=swap');

.footer-section {
    background-color: #000;
    padding: 100px 0px 30px 0px;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.footer-section .footer-menu-title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
}

.footer-section .foot-menu-div {
    margin-bottom: 7px;
    position: relative;
    z-index: 999;
}

.footer-section .foot-menu-div .foot-menu {
    color: #e7e7e7;
    font-size: 15px;
    font-family: 'Montserrat';
}

.footer-section .footlogo {
    width: 50%;
}

.footer-section .foot-cont {
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 0px;
    width: 80%;
}

.footer-section .foot-menu-div .icccc {
    width: 20px;
    height: 20px;
    margin: 0px 5px 0px 0px;
}

.footer-section .foot-menu-div .icccc1 {
    width: 25px;
    height: 26px;
    margin: 0px 7px 0px 0px;
}

.footer-section .bod-bot {
    height: 1px;
    background-color: #fff;
    width: 100%;
    margin-top: 30px;
    position: relative;
    z-index: 999;
}

.footer-section .copyrights-div p {
    margin-bottom: 0px;
    color: #fff;
    font-size: 13px;
}

.footer-section .copyrights-div {
    text-align: center;
    margin-top: 20px;
    position: relative;
    z-index: 999;
}

.footer-section .foot-layerr {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
    opacity: 0.5;
}

@media (min-width:0px) and (max-width:767.98px) {
    .footer-section .footer-menu-title {
        font-size: 15px;
        margin-top: 20px;
    }

    .footer-section .foot-menu-div .foot-menu {
        font-size: 12px;
    }

    .footer-section .foot-cont {
        font-size: 12px;
    }

    .footer-section .footlogo {
        width: 100px;
    }

    .footer-section {
        padding: 30px 10px 30px 10px;
    }

    .footer-section .foot-layerr {
        width: 97%;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .footer-section .footer-menu-title {
        font-size: 15px;
    }

    .footer-section .foot-menu-div .foot-menu {
        font-size: 13px;
    }

    .footer-section .foot-cont {
        font-size: 13px;
    }

    .footer-section .copyrights-div p {
        font-size: 10px;
    }
}

@media (min-width:992px) and (max-width:1400px) {
    .footer-section .footer-menu-title {
        font-size: 16px;
    }

    .footer-section .foot-menu-div .foot-menu {
        font-size: 12px;
    }

    .footer-section .foot-cont {
        font-size: 14px;
    }

    .footer-section .copyrights-div p {
        font-size: 12px;
    }
}