@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:wght@300;400;500&display=swap");

section.Support-Page {
  margin-bottom: 4%;
  margin-top: 5%;
}

.Support-Page h1.help {
  text-align: center;
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 700;
}

.Support-Page p.support {
  text-align: center;
  font-size: 13px;
  color: #d72c2c;
  font-weight: 400;
}

.Support-Page .Support-image img.support {
  width: 100%;
  height: auto;
}

.Support-Page .Support-image {
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
}

.Support-Page .support-content {
  position: absolute;
  top: 37%;
  left: 40%;
}

.Support-Page .accordion-content .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
  border-color: rgba(255, 255, 255, 0.125) !important;
}

.Support-Page .accordion-content .accordion .accordion-item .accordion-header button.accordion-button.collapsed {
  box-shadow: none !important;
  background: #f7f6fc;
  margin-bottom: 12px;
  font-size: 15px;
}

.Support-Page .accordion-content .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  box-shadow: none !important;
  color: black;
  background-color: #f7f6fc !important;
  font-size: 15px;
}

.Support-Page .accordion-content .accordion .accordion-item {
  border: none !important;
}

.Support-Page .accordion-content .accordion {
  -webkit-transition: height 0.8s ease-in-out;
  transition: height 0.8s ease-in-out;
}

.Support-Page .accordion-content .accordion .accordion-item .accordion-collapse .accordion-body {
  overflow: hidden;
  margin-bottom: 12px;
  background-color: #f7f6fc !important;
  font-size: 13px;
}

/* media Query Started */
@media (min-width: 0px) and (max-width: 320px) {
  .Support-Page .support-content {
    top: 44%;
    left: 40px;
  }

  .Support-Page h1.help {
    font-size: 16px;
  }

  .Support-Page p.support {
    font-size: 11px;
  }

  .Support-Page .accordion-content .accordion .accordion-item .accordion-header button.accordion-button.collapsed {
    font-size: 13px;
  }
}


@media (min-width: 486px) and (max-width: 575px) {
  .Support-Page .support-content {
    top: 41%;
    left: 27%;
  }

  .Support-Page h1.help {
    font-size: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Support-Page .support-content {
    left: 30%;
    top: 36%;
  }

  .Support-Page h1.help {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Support-Page .support-content {
    top: 34%;
    left: 35%;
  }

  .Support-Page h1.help {
    font-size: 22px;
  }
}