.download-brochuer {
    margin: 8% 0;
}

.download-brochuer .back-iamge {
    border-radius: 50px;
    background-image: url('../../../public/assets/images/banner/bg-image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 404px; */

}

.download-brochuer .download-heading h5 {
    font-size: 32px;
    font-weight: bold;
}

.download-brochuer .download-heading p {
    letter-spacing: 0.32px;
    font-size: 16px;
    max-width: 550px;
    margin: auto;
}

.download-brochuer .button-holder .bth-download {
    width: 119px;
    height: 40px;
    background: #ED1B24 0% 0% no-repeat padding-box;
    font-size: 14px;
    color: #FFF;
    border-radius: 0%;
}

.download-brochuer .button-holder .bth-download:hover {
    width: 119px;
    height: 40px;
    background-color: black;
    color: #ED1B24;
    border-radius: 0%;
    font-size: 14px;
}

@media(min-width:0px) and (max-width:767px) {
    .download-brochuer .back-iamge {
        margin: 0px;
    }

    .download-brochuer .download-heading h5 {
        font-size: 22px;
    }

    .download-brochuer .download-heading p {

        font-size: 13px;

    }
}

@media (min-width:768px) and (max-width:1200px) {
    .download-brochuer .download-heading h5 {
        font-size: 25px;
    }

    .download-brochuer .download-heading p {
        font-size: 13px;
    }

    .download-brochuer .back-iamge {
        height: auto;
    }
}