.our-about {
    background-image: url('../../../public/assets/images/banner/About_Us.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 680px;

}

.our-about .list-holder ul li span {
    font-size: 16px;
    color: #00000096;
    padding-bottom: 10px;
    /* font-family: 'Source Sans Pro '; */
}

.our-about .list-holder ul li {
    font-size: 20px;
}

.our-about .heading-holder {
    margin-top: 20%;
}

.our-about .heading-holder ul {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    text-align: justify;
}

.our-about .heading-holder h5 {
    font-size: 18px;
    font-weight: 600;
    color: #ED1B24;
    font-family: 'Open Sans';
}

.our-about .heading-holder h4 {
    text-transform: capitalize;
    font-size: 28px;
    font-weight: bold;
    max-width: 500px;
    font-family: 'Open Sans';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    line-clamp: 5;
    text-align: justify;
}

.our-about .heading-holder p {
    font-size: 18px;
    font-weight: 300;
    color: #00000096;
    text-align: justify;
    max-width: 420px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    text-align: justify;
    /* font-family: 'Source Sans Pro light'; */
}

.our-about .list-holder ul li {
    font-size: 16px;
    color: #00000096;
    padding-bottom: 3px;
    /* font-family: 'Source Sans Pro'; */
}

.our-about .list-holder ul li::marker {
    color: #000;
    font-size: 22px;
}

.our-about .image-holder {
    overflow: hidden;
    border-radius: 100%;
    width: 472px;
    height: 472px;
    margin-top: 30%;

}

.our-about .image-holder .back_img {
    width: 472px;
    height: 472px;
    border-radius: 100%;
    transition: 0.3s;
}

.our-about .image-holder:hover .back_img {
    transform: scale(1.1);
    transition: 0.3s;
}

.our-about .button-holder .btn-read {
    font-size: 14px;
    background: #ED1B24;
    color: #FFF;
    width: 119px;
    height: 40px;
    border-radius: 0%;
}

.our-about .button-holder .btn-read:hover {
    background-color: black;
    font-size: 14px;
    color: #ED1B24;
    width: 119px;
    height: 40px;
    border-radius: 0%;
}

.our-about .about-list {
    padding-left: 23px;
}



@media(min-width:0px) and (max-width:320px) {
    .our-about .heading-holder h4 {
        font-size: 16px;

    }

    .our-about .heading-holder p {
        font-size: 15px;
    }

    .our-about .list-holder ul li span {
        font-size: 14px;

    }

    .our-about .button-holder .btn-read:hover {
        font-size: 12px;
        width: 109px;
        height: 38px;

    }

    .our-about .button-holder .btn-read {
        font-size: 12px;
        width: 109px;
        height: 38px;

    }

    .our-about .heading-holder {
        margin-top: 13%;
    }

    .our-about .image-holder {
        margin-top: 9%;
    }

    .our-about .image-holder .back_img {
        width: 300px;
        height: 300px;

    }

    .our-about .image-holder {
        width: auto;
        height: auto;
    }
}

@media(min-width:321px) and (max-width:576px) {
    .our-about .image-holder {

        margin-top: 7%;
    }

    .our-about .heading-holder {
        margin-top: 8%;
    }

    .our-about .heading-holder h4 {

        font-size: 17px;

    }

    .our-about .heading-holder p {
        font-size: 16px;

    }

    .our-about .list-holder ul li span {
        font-size: 15px;

    }

    .our-about .image-holder .back_img {
        width: 300px;
        height: 300px;

    }

    .our-about .image-holder {
        width: auto;
        height: auto;
    }

}

@media(min-width:578px) and (max-width:767px) {
    .our-about .heading-holder {
        margin-top: 13%;
    }

    .our-about .heading-holder h4 {

        font-size: 15px;

    }

    .our-about .heading-holder p {
        font-size: 14px;

    }

    .our-about .list-holder ul li span {
        font-size: 14px;

    }


    .our-about .image-holder {
        margin-top: 34%;
    }

    .our-about .image-holder .back_img {
        width: 200px;
        height: 200px;

    }

    .our-about .image-holder {
        width: auto;
        height: auto;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .our-about .heading-holder h4 {
        font-size: 13px;
    }

    .our-about .heading-holder p {
        font-size: 16px;

    }

    .our-about .list-holder ul li {
        font-size: 14px;
        padding-bottom: 5px;

    }

    .our-about {
        margin: 0% 0;
        height: 471px;
    }

    .our-about .image-holder {
        width: auto;
        height: auto;
    }

    .our-about .image-holder {
        margin-top: 34%;
    }

    .our-about .image-holder .back_img {
        width: 272px;
        height: 272px;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .our-about {
        height: 616px;
    }

    .our-about .image-holder {
        width: 390px;
        height: 390px;
        margin-top: 51%;
    }

    .our-about .image-holder .back_img {
        width: 390px;
        height: 390px;
    }

}

@media(min-width:1201px) and (max-width:1400px) {
    .our-about {
        height: 651px;
    }

    .our-about .image-holder {
        margin-top: 36%;
    }
}

@media(min-width:1401px) and (max-width:1600px) {

    .our-about .image-holder {
        margin-top: 27%;
    }
}