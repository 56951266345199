.terms-condition {
    margin-top: 5%;
    margin-bottom: 2%;
}

.terms-condition .heading-about h4 {
    font-size: 28px;
    font-weight: bold;

}

.terms-condition .border-red {
    background: #ED1B24;
    height: 3px;
    width: 87px;
}

.terms-condition .border-line {
    height: 1px;
    background: #000000;
    width: 197px;
    margin-top: 1px;
}

.terms-condition .border-red1 {
    background: #ED1B24;
    height: 3px;
    width: 85px;
}

.terms-condition .border-line1 {
    height: 1px;
    background: #000000;
    width: 100px;
    margin-top: 1px;
}

.terms-condition .rentangle-box {
    background: #ED1B24 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    width: 10px;
    height: 10px;
}

.terms-condition .heading-name h5 {
    font-size: 16px;
    font-weight: 600;
}

.terms-condition .paragraph-name p {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.32px;
    margin-bottom: 3%;
}

.terms-condition .alphabat-holder span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.32px;
}

@media(min-width:0px) and (max-width:320px) {
    .terms-condition {
        margin-top: 18%;

    }

    .terms-condition .heading-about h4 {
        font-size: 22px;
    }

    .terms-condition .border-red {
        width: 72px;
    }

    .terms-condition .border-line {
        width: 151px;
    }

    .terms-condition .border-red1 {
        width: 63px;
    }

    .terms-condition .border-line1 {
        width: 83px;
    }

    .terms-condition .paragraph-name p {
        font-size: 14px;

    }


}

@media(min-width:321px) and (max-width:578px) {
    .terms-condition {
        margin-top: 18%;

    }

    .terms-condition .heading-about h4 {
        font-size: 22px;
    }

    .terms-condition .border-red {
        width: 72px;
    }

    .terms-condition .border-line {
        width: 151px;
    }

    .terms-condition .paragraph-name p {
        font-size: 14px;

    }

    .terms-condition .border-red1 {
        width: 63px;
    }

    .terms-condition .border-line1 {
        width: 83px;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .terms-condition {
        margin-top: 8%;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .terms-condition {
        margin-top: 8%;

    }
}

@media(min-width:1201px) and (max-width:1500px) {
    .terms-condition {
        margin-top: 8%;

    }
}