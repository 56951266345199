/* news css started */

section.News {
  margin-top: 5%;
  margin-bottom: 3%;
}

.News h1.news-heading {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  font-family: "Source Sans Pro";
}

.News p.text {
  color: #00000096;
  font-size: 15px;
  letter-spacing: 0.32px;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.News .newsCard {
  height: 400px;
  position: relative;
}

.News h1.news-detail {
  color: #000;
  font-size: 29px;
  font-weight: bold;
  text-align: center;
}

.News .borders .border-red {
  background: #ed1b24;
  height: 3px;
  width: 78px;
}

.News .borders .border-line {
  height: 1px;
  background: #000000;
  width: 158px;
  margin-top: 1px;
}

.News .card .card-body button.btn.btn-read {
  border-radius: 0px;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 2px solid black;
  width: fit-content;
  cursor: pointer;
}

.News .card .card-body button.btn.btn-read {
  border-radius: 0px;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  width: -moz-fit-content;
  cursor: pointer;
}

.News .Read-btn a {
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.News .Newscard_img {
  width: 100%;
  height: 200px;
}

.News .Read-btn {
  position: absolute;
  bottom: 10px;
}

.News .card .card-body button.btn.btn-read .Read-btn {
  text-align: center;
}

.News .card .card-body button.btn.btn-read .read-border {}

/* news css end */

/* media query started */

@media (min-width: 0px) and (max-width: 320px) {
  .News h1.news-detail {
    font-size: 18px;
  }

  .News .borders .border-line {
    width: 92px;
  }

  .News .borders .border-red {
    width: 54px;
  }

  .News h1.news-heading {
    font-size: 16px;
  }

  .News p.text {
    font-size: 14px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .News h1.news-detail {
    font-size: 19px;
  }

  .News .borders .border-line {
    width: 101px;
  }

  .News .borders .border-red {
    width: 53px;
  }

  .News h1.news-heading {
    font-size: 16px;
  }

  .News p.text {
    font-size: 14px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .News h1.news-detail {
    font-size: 22px;
  }

  .News .borders .border-line {
    width: 114px;
  }

  .News .borders .border-red {
    width: 68px;
  }

  .News h1.news-heading {
    font-size: 18px;
  }

  .News p.text {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .News h1.news-detail {
    font-size: 24px;
  }

  .News .borders .border-line {
    width: 119px;
  }

  .News .borders .border-red {
    width: 76px;
  }

  .News h1.news-heading {
    font-size: 18px;
  }

  .News p.text {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .News h1.news-detail {
    font-size: 24px;
    margin-top: 8%;
  }

  .News .borders .border-line {
    width: 143px;
  }

  .News .borders .border-red {
    width: 59px;
  }

  .News h1.news-heading {
    font-size: 18px;

  }

  .News p.text {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .News h1.news-detail {
    font-size: 26px;
  }

  .News .borders .border-line {
    width: 135px;
  }

  .News .borders .border-red {
    width: 80px;
  }

  .News h1.news-heading {
    font-size: 18px;
  }

  .News p.text {
    font-size: 14px;
  }
}