.Hover-cardd-section{
    margin-top:5%;
}

.Hover-cardd-section .card-image-div {
    position: relative;
    transition: all .4s ease-in-out 0s;
}
.Hover-cardd-section  .main-card  .card-image-div::before {
    content: "";
    display: none;
    top: 10%;
    position: absolute;
    margin: auto;
    z-index: 9;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    bottom: 10%;
    left: 8%;
    width: 0%;
    transition: 0.4s;
    transition: all .4s ease-in-out 0s;
    display: inline-block;

}

.Hover-cardd-section  .main-card  .card-image-div:hover::before {
    content: "";
    display: block;
    top: 10%;
    position: absolute;
    margin: auto;
    z-index: 9;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    bottom: 10%;
    left: 8%;
    width: 85%;
    transition: 0.4s;
    transition: all .4s ease-in-out 0s;
}




.Hover-cardd-section  .main-card  .card-image-div::after {
    content: "";
    display: block;
    top: 10%;
    position: absolute;
    margin: auto;
    z-index: 9;
    border-left: 0px solid #fff;
    border-right: 0px solid #fff;
    bottom: 10%;
    left: 10%;
    right: 10%;
    width: 0%;
    height: 90%;
    transition: 0.4s;
    transition: all .4s ease-in-out 0s;
}

.Hover-cardd-section  .main-card  .card-image-div:hover::after {
    content: "";
    display: block;
    top: 10%;
    position: absolute;
    margin: auto;
    z-index: 9;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    bottom: 10%;
    left: 10%;
    right: 10%;
    width: 75%;
    height: 90%;
    transition: 0.4s;
    transition: all .4s ease-in-out 0s;
}





.Hover-cardd-section .main-card .card-image-div  .card-images{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.Hover-cardd-section  .main-card  .card-image-div:hover .white-overlay{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #ffffff38;
    transition: all .2s ease-in-out 0s;
}   

.Hover-cardd-section .swiper-button-prev{
    display: none;
}
.Hover-cardd-section .swiper-button-next{
    display: none;
}
.Hover-cardd-section .swiper-pagination {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
    /* bottom: -35px; */
}