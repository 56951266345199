@font-face {
    font-family: Bebas Kai;
    src: url('../../public/assets/images/BebasKai.ttf');
}

.our-client {
    margin-top: 5%;
    margin-bottom: 5%;
}

.our-client {
    margin-top: 6%;
    margin-bottom: 40px;
}

.our-client .swiper-pagination-bullet {
    display: none;
}

.our-client .swiper-pagination-bullet-active {
    display: none;
}

.our-client .swiper-button-prev:after {
    content: url('../../public/assets/images/icon/prev1.png');

}

.our-client .swiper-button-next:after {
    content: url('../../public/assets/images/icon/next1.png');


}

.our-client .swiper-button-next {
    right: 0px;

}

.our-client .swiper-button-prev {
    left: 0px;
}

.our-client .heading-about h4 {
    font-size: 29px;
    font-weight: bold;
}

.our-client .border-red {
    background: #ED1B24;
    height: 3px;
    width: 63px;
}

.our-client .border-line {
    height: 1px;
    background: #000000;
    width: 107px;
    margin-top: 1px;
}

.our-client .client-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    aspect-ratio: 1/1;
    background-position: center;
    margin: auto;
}

.our-client .client-brand .client-partner {
    width: 106px;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
}

.our-client .border-slides {
    box-shadow: inset 0px 3px 11px #00000029;
    border: 1px solid #ED1B24;
    border-radius: 36px;
}

.our-client .tiger-overlay {
    position: relative;
}

.our-client .tiger-overlay .text-overlay {
    /* position: absolute;
  top: 18%;
  left: 5%;
  text-align: end; */
}

.our-client .leader-box .main-text {
    position: absolute;
    top: 18%;
    left: 5%;
    text-align: end;
}

.our-client .text-overlay p.leader-text {
    font-size: 45px;
    color: #FFF;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.our-client .text-overlay p.someone-text {
    font-size: 30px;
    color: #FFF;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.our-client .leader-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    position: relative;

}

.our-client .leader-box:hover {

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #ED1B24B3;
    border: 1px solid #707070;
    border-radius: 10px;
}

/* .our-client .leader-img {
    height: auto;
    width: 100%;
    padding: 20px 40px;
    transition: transform .5s;
}

.our-client .leader-box .leader-img:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
} */
.our-client .tiger-overlay {
    position: relative;
    margin-top: 60px;
}

.our-client .tiger-overlay .text-overlay {
    position: absolute;
    top: 10%;
    left: 10%;
    text-align: end;
}

.our-client .tiger-overlay .text-overlay p.leader-text {
    font-size: 65px;
    /* font-weight: bold; */
    color: #FFF;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-family: 'Bebas Kai';
}

.our-client .tiger-overlay .text-overlay p.leader-text1 {
    font-size: 59px;
    /* font-weight: bold; */
    color: #FFF;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-family: 'Bebas Kai';
}

.our-client .tiger-overlay .text-overlay p.leader-text2 {
    font-size: 49px;
    /* font-weight: bold; */
    color: #FFF;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-family: 'Bebas Kai';
}

.our-client .tiger-overlay .text-overlay p.someone-text {
    font-size: 38px;
    /* font-weight: bold; */
    color: #FFF;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-family: 'Bebas Kai';
}

.our-client .leader-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;

}

.our-client .leader-box:hover {

    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #ED1B24B3;
    border: 1px solid #707070;
    border-radius: 10px;
}

.our-client .leader-img {
    height: 100%;
    width: 100%;
    padding: 20px 40px;
    transition: transform .5s;
}

.our-client .leader-box .leader-img:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

/* media query start */

@media(min-width:0px) and (max-width:320px) {
    .our-client .leader-img {
        padding: 10px 12px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 12px;
    }

    .our-client .tiger-overlay .text-overlay p.someone-text {
        font-size: 12px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 14px;

    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 13px;
    }

    .our-client .tiger-overlay .text-overlay {
        top: 20%;
        left: 10%;
    }

    .our-client .heading-about h4 {
        font-size: 18px;
    }

    .our-client .border-line {
        width: 60px;
    }

    .our-client {
        margin-top: 17%;
        margin-bottom: 20px;
    }

    .our-client .tiger-overlay {
        margin-top: 30px;
    }

    .our-client .border-red {
          width: 32px;
    }

    .our-client .border-line {
        width: 60px;
    }
}

@media(min-width:321px) and (max-width:480px) {
    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 18px;
    }

    .our-client .tiger-overlay .text-overlay p.someone-text {
        font-size: 12px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 16px;

    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 14px;
    }

    .our-client .tiger-overlay .text-overlay {
        top: 16%;
        left: 14%;
    }

    .our-client .leader-img {
        padding: 10px 12px;
    }

    .our-client .heading-about h4 {
        font-size: 18px;
    }

    .our-client .border-line {
        width: 68px;
    }

    .our-client {
        margin-top: 17%;
        margin-bottom: 20px;
    }

    .our-client .tiger-overlay {
        margin-top: 30px;
    }

    .our-client .border-slides {

        margin: 0px 4%;
    }

    .our-client .client-brand {
        aspect-ratio: 0;
    }

    .our-client .border-red {

        width: 34px;
    }

    .our-client .border-line {
        width: 60px;
    }
}

@media(min-width:481px) and (max-width:576px) {
    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 27px;
    }

    .our-client .tiger-overlay .text-overlay p.someone-text {
        font-size: 18px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 25px;

    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 22px;
    }

    .our-client .tiger-overlay .text-overlay {
        top: 16%;
        left: 14%;
    }

    .our-client .leader-img {
        padding: 12px 20px;
    }

    .our-client .heading-about h4 {
        font-size: 18px;
    }

    .our-client {
        margin-top: 10%;
        margin-bottom: 20px;
    }

    .our-client .tiger-overlay {
        margin-top: 30px;
    }

    .our-client .border-slides {

        margin: 0px 4%;
    }

    .our-client .client-brand {

        aspect-ratio: 0;
    }

    .our-client .border-red {

        width: 46px;
    }

    .our-client .border-line {
        width: 60px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 32px;
    }

    .our-client .tiger-overlay .text-overlay p.someone-text {
        font-size: 22px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 29px;

    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 25px;
    }

    .our-client .tiger-overlay .text-overlay {
        top: 11%;
        left: 10%;
    }

    .our-client .leader-img {
        padding: 12px 20px;
    }

    .our-client .heading-about h4 {
        font-size: 18px;
    }

    .our-client .border-line {
        width: 68px;
    }

    .our-client .tiger-overlay {
        margin-top: 30px;
    }

    .our-client .border-red {

        width: 46px;
    }

    .our-client .border-line {
        width: 60px;
    }

}

@media(min-width:769px) and (max-width:991px) {
    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 40px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 38px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 30px;
    }

    .our-client .tiger-overlay .text-overlay p.someone-text {
        font-size: 30px;
    }

    .our-client {
        margin-top: 8%;
    }
}

@media(min-width:992px) and (max-width:1199px) {

    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 50px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 45px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 30px;
    }

    .our-client .tiger-overlay .text-overlay p.someone-text {
        font-size: 30px;
    }

    .our-client {
        margin-top: 8%;
    }
}


@media(min-width:1200px) and (max-width:1400px) {

    .our-client .tiger-overlay .text-overlay p.leader-text {
        font-size: 60px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text1 {
        font-size: 55px;
    }

    .our-client .tiger-overlay .text-overlay p.leader-text2 {
        font-size: 40px;
    }

    .our-client {
        margin-top: 8%;
    }
}