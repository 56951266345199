/* css started */

.CCTV-System .Heading-cctv .heading-about h4 {
  font-size: 29px;
  font-weight: bold;
  color: #000000;
}

.CCTV-System {
  margin-top: 5%;
  margin-bottom: 2%;
}

.CCTV-System .Heading-cctv .borders .border-red {
  background: #ed1b24;
  height: 3px;
  width: 75px;
}

.CCTV-System .card-text p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 5;
  text-align: justify;
}

.CCTV-System .read-button {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.CCTV-System .Heading-cctv .borders .border-line {
  height: 1px;
  background: #000000;
  width: 109px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders {
  width: 100%;
}

.CCTV-System .IR-Camera {
  height: 500px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000078;
  border-radius: 40px;
  opacity: 1;
  position: relative;
}

.CCTV-System .IR-Camera .Heading-Main .dome-camera {
  border-bottom: 2px solid #ed1b24;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  text-align: center;
  padding: 18px 0px 9px 0px;
  width: fit-content;
}

.CCTV-System .Heading-Main {
  text-align: -webkit-center;
}

.CCTV-System .IR-Camera .IR-camera-img .camera-img {
  width: 200px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
}

.CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
  transform: scale(1.4);
}

.CCTV-System .IR-Camera .IR-camera-img {
  text-align: center;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
}

.CCTV-System .IR-Camera .IR-Camera-body {
  height: 143px;
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 35px 35px;
  opacity: 1;
}

.CCTV-System .IR-Camera .IR-Camera-body p.card-text {
  padding: 13px 0px 0px 0px;
  font-weight: 300;
  color: #6f6f6f;
  font-size: 15px;
  text-align: center;
  font-family: sans-serif;
}

.CCTV-System .IR-Camera .IR-Camera-body button.btn.btn-read {
  font-weight: 600;
  margin-bottom: -6px;
  color: #000000;
  font-size: 15px;
  border: none;
}



.CCTV-System .IR-Camera .IR-Camera-body .read-button .read-border {
  margin: auto;
  width: 76px;
  border-bottom: 3px solid black;
}

.CCTV-System .Heading-cctv .borders-one .border-red {
  background: #ed1b24;
  height: 3px;
  width: 45px;
}

.CCTV-System .Heading-cctv .borders-one .border-line {
  height: 1px;
  background: #000000;
  width: 100px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-one {
  width: 100%;
}

/* access border */

.CCTV-System .Heading-cctv .borders-two .border-red {
  background: #ed1b24;
  height: 3px;
  width: 97px;
}

.CCTV-System .Heading-cctv .borders-two .border-line {
  height: 1px;
  background: #000000;
  width: 224px;
  margin-top: 1px;
}

/* 
.CCTV-System .Heading-cctv .borders-two {
  width: 100%;
} */

/* intrusion system */

.CCTV-System .Heading-cctv .borders-three .border-red {
  background: #ed1b24;
  height: 3px;
  width: 122px;
}

.CCTV-System .Heading-cctv .borders-three .border-line {
  height: 1px;
  background: #000000;
  width: 207px;
  margin-top: 1px;
}

/* .CCTV-System .Heading-cctv .borders-three {
  width: 100%;
} */

/* car parking system */
.CCTV-System .Heading-cctv .borders-four .border-red {
  background: #ed1b24;
  height: 3px;
  width: 105px;
}

.CCTV-System .Heading-cctv .borders-four .border-line {
  height: 1px;
  background: #000000;
  width: 162px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-four {
  width: 100%;
}

/* metal detection system */
.CCTV-System .Heading-cctv .borders-five .border-red {
  background: #ed1b24;
  height: 3px;
  width: 128px;
}

.CCTV-System .Heading-cctv .borders-five .border-line {
  height: 1px;
  background: #000000;
  width: 193px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-five {
  width: 100%;
}

/* data solution */
.CCTV-System .Heading-cctv .borders-six .border-red {
  background: #ed1b24;
  height: 3px;
  width: 124px;
}

.CCTV-System .Heading-cctv .borders-six .border-line {
  height: 1px;
  background: #000000;
  width: 179px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-six {
  width: 100%;
}

/* home automation*/
.CCTV-System .Heading-cctv .borders-seven .border-red {
  background: #ed1b24;
  height: 3px;
  width: 147px;
}

.CCTV-System .Heading-cctv .borders-seven .border-line {
  height: 1px;
  background: #000000;
  width: 226px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-seven {
  width: 100%;
}

/* currency*/
.CCTV-System .Heading-cctv .borders-eight .border-red {
  background: #ed1b24;
  height: 3px;
  width: 291px;
}

.CCTV-System .Heading-cctv .borders-eight .border-line {
  height: 1px;
  background: #000000;
  width: 335px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-eight {
  width: 100%;
}

/* network*/
.CCTV-System .Heading-cctv .borders-nine .border-red {
  background: #ed1b24;
  height: 3px;
  width: 172px;
}

.CCTV-System .Heading-cctv .borders-nine .border-line {
  height: 1px;
  background: #000000;
  width: 304px;
  margin-top: 1px;
}

.CCTV-System .Heading-cctv .borders-nine {
  width: 100%;
}

/* css end */


/* ************************************Media Query Started************************************ */
@media (min-width: 0px) and (max-width: 320px) {
  .CCTV-System {
    margin-top: 15%;
  }

  .CCTV-System .IR-Camera {
    height: auto;
  }

  .CCTV-System .Heading-cctv .borders-one .border-line {
    width: 83px;
  }

  /* .CCTV-System .IR-Camera .IR-camera-img {
    width: auto;
    height: auto;
    aspect-ratio: auto;
  } */
  .CCTV-System .IR-Camera .IR-Camera-body .read-button {
    margin-top: -5%;
  }

  .CCTV-System .Heading-cctv .heading-about h4 {
    font-size: 22px;
  }

  .CCTV-System .Heading-cctv .borders .border-line {
    width: 84px;
  }

  .CCTV-System .Heading-cctv .borders .border-red {
    width: 55px;
  }

  .CCTV-System .IR-Camera .Heading-Main .dome-camera {
    font-size: 16px;
  }

  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .IR-Camera .IR-Camera-body p.card-text {
    font-size: 14px;
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
    transform: scale(1.0);
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img {
    width: 164px !important;
  }

  .CCTV-System .IR-Camera .IR-camera-img {
    height: 160px;
  }

  .CCTV-System .Heading-cctv .borders-three .border-line {
    width: 157px;
  }

  .CCTV-System .Heading-cctv .borders-three .border-red {
    width: 99px;
  }

  .CCTV-System .Heading-cctv .borders-one .border-line {
    width: 73px;
  }

  .CCTV-System .Heading-cctv .borders-two .border-line {
    width: 177px;
  }

  .CCTV-System .Heading-cctv .borders-two .border-red {
    width: 76px;
  }

  .CCTV-System .Heading-cctv .borders-four .border-red {
    width: 91px;
  }

  .CCTV-System .Heading-cctv .borders-four .border-line {
    width: 122px;
  }

  .CCTV-System .Heading-cctv .borders-five .border-line {
    width: 151px;
  }

  .CCTV-System .Heading-cctv .borders-five .border-red {
    width: 98px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-red {
    width: 91px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-line {
    width: 141px;
  }

  .CCTV-System .Heading-cctv .borders-seven .border-red {
    width: 130px;
  }

  .CCTV-System .Heading-cctv .borders-seven .border-line {
    width: 154px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-line {
    width: 173px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-red {
    width: 168px;
  }

  .CCTV-System .Heading-cctv .borders-nine .border-red {

    width: 120px;
  }

  .CCTV-System .Heading-cctv .borders-nine .border-line {
    width: 124px;
  }

  .CCTV-System .card-text p {
    margin-bottom: 32px;
  }
}

@media(min-width:321px) and (max-width:420px) {
  .CCTV-System .Heading-cctv .borders-nine .border-red {

    width: 120px;
  }

  .CCTV-System .Heading-cctv .borders-nine .border-line {
    width: 137px;
  }

  .CCTV-System .card-text p {
    margin-bottom: 32px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .CCTV-System {
    margin-top: 10%;
  }

  .CCTV-System .Heading-cctv .heading-about h4 {
    font-size: 24px;
  }

  .CCTV-System .card-text p {
    margin-bottom: 32px;
  }

  .CCTV-System .IR-Camera {
    height: auto;
  }

  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .Heading-cctv .borders .border-line {
    width: 86px;
  }

  .CCTV-System .Heading-cctv .borders .border-red {
    width: 67px;
  }

  .CCTV-System .IR-Camera .Heading-Main .dome-camera {
    font-size: 18px;
  }

  .CCTV-System .IR-Camera .IR-Camera-body p.card-text {
    font-size: 14px;
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
    transform: scale(1.0);
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img {
    width: 150px;
  }

  .CCTV-System .IR-Camera .IR-camera-img {
    height: 300px;
  }

  .CCTV-System .Heading-cctv .borders-one .border-line {
    width: 83px;
  }

  .CCTV-System .Heading-cctv .borders-two .border-line {
    width: 178px;

  }

  .CCTV-System .Heading-cctv .borders-three .border-line {
    width: 152px;
  }

  .CCTV-System .Heading-cctv .borders-four .border-red {
    width: 98px;
  }

  .CCTV-System .Heading-cctv .borders-four .border-line {
    width: 130px;
  }

  .CCTV-System .Heading-cctv .borders-five .border-line {
    width: 151px;
  }

  .CCTV-System .Heading-cctv .borders-five .border-red {
    width: 120px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-red {
    width: 107px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-line {
    width: 141px;
  }

  .CCTV-System .Heading-cctv .borders-seven .border-red {
    width: 151px;
  }

  .CCTV-System .Heading-cctv .borders-seven .border-line {
    width: 154px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-line {
    width: 173px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-red {
    width: 168px;
  }


}

@media (min-width: 486px) and (max-width: 575px) {
  .CCTV-System {
    margin-top: 8%;
  }

  .CCTV-System .Heading-cctv .heading-about h4 {
    font-size: 25px;
  }

  .CCTV-System .Heading-cctv .borders .border-line {
    width: 96px;
  }

  .CCTV-System .Heading-cctv .borders .border-red {
    width: 63px;
  }

  .CCTV-System .IR-Camera {
    height: auto;
  }

  .CCTV-System .card-text p {
    margin-bottom: 32px;
  }

  /* .CCTV-System .IR-Camera .IR-camera-img {
    width: auto;
    height: auto;
    aspect-ratio: auto;
  } */
  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
    transform: scale(1.0);
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img {
    width: 120px;
  }

  .CCTV-System .IR-Camera .IR-camera-img {
    height: 220px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .CCTV-System .IR-Camera {
    height: auto;
  }

  /* .CCTV-System .IR-Camera .IR-camera-img {
    width: auto;
    height: auto;
    aspect-ratio: auto;
    padding: 4px 0px;
  } */
  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
    transform: scale(1.0);
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img {
    width: 140px;
  }

  .CCTV-System .IR-Camera .IR-camera-img {
    height: 240px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .CCTV-System .IR-Camera {
    height: auto;
  }

  /* .CCTV-System .IR-Camera .IR-camera-img {
    width: auto;
    height: auto;
    aspect-ratio: auto;
    padding: 4px 0px;
  } */
  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .card-text p {
    margin-bottom: 32px;
  }

  .CCTV-System .Heading-cctv .heading-about h4 {
    font-size: 25px;
  }

  .CCTV-System {
    margin-top: 6%;
  }

  .CCTV-System .Heading-cctv .borders .border-line {
    width: 96px;
  }

  .CCTV-System .Heading-cctv .borders .border-red {
    width: 61px;
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
    transform: scale(1.1);
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img {
    width: 140px;
  }

  .CCTV-System .IR-Camera .IR-camera-img {
    height: 220px;
  }
}

@media(min-width:768px) and (max-width:1200px) {
  .CCTV-System .Heading-cctv .borders-one .border-line {
    width: 86px;
  }

  .CCTV-System .Heading-cctv .borders-two .border-red {
    width: 97px;
  }

  .CCTV-System .Heading-cctv .borders-two .border-line {
    width: 187px;
  }

  .CCTV-System .Heading-cctv .borders-three .border-red {
    width: 122px;
  }

  .CCTV-System .Heading-cctv .borders-three .border-line {
    width: 165px;
  }

  .CCTV-System .Heading-cctv .borders-four .border-line {
    width: 134px;

  }

  .CCTV-System .Heading-cctv .borders-five .border-line {
    width: 155px;

  }

  .CCTV-System .Heading-cctv .borders-six .border-red {
    width: 109px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-line {
    width: 150px;

  }

  .CCTV-System .Heading-cctv .borders-seven .border-red {

    width: 135px;
  }

  .CCTV-System .Heading-cctv .borders-seven .border-line {
    width: 185px;

  }

  .CCTV-System .Heading-cctv .borders-eight .border-red {

    width: 248px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-line {
    width: 294px;

  }

  .CCTV-System .Heading-cctv .borders-nine .border-red {

    width: 172px;
  }

  .CCTV-System .Heading-cctv .borders-nine .border-line {
    width: 241px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .CCTV-System {
    margin-top: 7%;
  }

  .CCTV-System .IR-Camera {
    height: 100%;
  }

  /* .CCTV-System .IR-Camera .IR-camera-img {
    width: auto;
    height: auto;
    aspect-ratio: auto;
  } */
  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .Heading-cctv .heading-about h4 {
    font-size: 25px;
  }

  .CCTV-System .Heading-cctv .borders .border-line {
    width: 96px;
  }

  .CCTV-System .Heading-cctv .borders .border-red {
    width: 61px;
  }

  .CCTV-System .IR-Camera .IR-camera-img .camera-img:hover {
    transform: scale(1.3);
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .CCTV-System {
    margin-top: 5%;
  }

  .CCTV-System .IR-Camera {
    height: 100%;
  }


  .CCTV-System .IR-Camera .IR-Camera-body {
    height: auto;
  }

  .CCTV-System .Heading-cctv .heading-about h4 {
    font-size: 25px;
  }

  .CCTV-System .Heading-cctv .borders .border-line {
    width: 96px;
  }

  .CCTV-System .Heading-cctv .borders .border-red {
    width: 61px;
  }

  .CCTV-System .Heading-cctv .borders-three .border-red {
    width: 105px;
  }

  .CCTV-System .Heading-cctv .borders-three .border-line {
    width: 174px;
  }

  .CCTV-System .Heading-cctv .borders-two .border-line {
    width: 186px;
  }

  .CCTV-System .Heading-cctv .borders-four .border-line {
    width: 138px;
  }

  .CCTV-System .Heading-cctv .borders-five .border-line {
    width: 156px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-red {
    width: 116px;
  }

  .CCTV-System .Heading-cctv .borders-six .border-line {
    width: 149px;

  }

  .CCTV-System .Heading-cctv .borders-seven .border-line {
    width: 182px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-red {

    width: 248px;
  }

  .CCTV-System .Heading-cctv .borders-eight .border-line {
    width: 294px;

  }

  .CCTV-System .Heading-cctv .borders-nine .border-red {

    width: 172px;
  }

  .CCTV-System .Heading-cctv .borders-nine .border-line {
    width: 241px;
  }
}



@media (min-width: 2000px) and (max-width: 2690px) {
  .CCTV-System {
    margin-top: 4%;
    margin-bottom: 2%;
  }
}

/* ************************************Media Query end************************************ */