.complain-lottey {
    width: 124px;
    margin-top: -63px;
    height: 256px;
}

@media(min-width:0px) and (max-width:320px) {
    .complain-lottey {
        width: 109px;
        margin-top: -66px;
        height: 242px;
    }
}

@media(min-width:321px) and (max-width:420px) {
    .complain-lottey {
        width: 117px;
        margin-top: -63px;
        height: 244px;
    }
}

@media(min-width:420px) and (max-width:578px) {
    .complain-lottey {
        width: 116px;
        margin-top: -63px;
        height: 247px;
    }
}