.home-banner .banner-slider {
    position: relative;
}

.home-banner .banner-slider .img-overlay {
    position: absolute;
    top: 14%;
    left: 0%;

}

.home-banner .banner-slider .img-overlay .text-holder h2 {
    font-size: 50px;
    color: #FFF;
    font-weight: 700;
}

.home-banner .banner-slider .img-overlay .text-holder p {
    font-size: 16px;
    color: #FFF;
}

.home-banner .video-class-grid {
    width: 100%;
    /* object-fit: cover; */
    height: 792px;
}



.home-banner .lottey-holder {
    position: absolute;
    bottom: 30px;
    z-index: 100;
    left: 49%;
    background-color: #000;
    border-radius: 26px;
    width: 38px;
    height: 69px;
}


.home-banner .swiper-button-prev {
    display: none;
}

.home-banner .swiper-button-next {
    display: none;
}


.home-banner .swiper-pagination-bullet {
    width: 21px;
    height: 6px;
    border-radius: 5px;
    opacity: 1;
    background-color: #FFF;
}

.home-banner .swiper-pagination-bullet-active {
    width: 41px;
    background: #f00;
}

.home-banner .swiper-pagination {
    bottom: 115px !important;
}

/* .home-banner .mobile-view {
    display: none;
} */

@media(min-width:0px) and (max-width:360px) {
    .home-banner .text-holder {
        margin-top: 6%;
    }

    .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 13px;
        color: #FFF;
    }

    .home-banner .img-overlay {
        position: absolute;
        top: 20%;
        left: 6%;
    }

    .home-banner .lottey-holder {
        bottom: 14px;
        left: 45%;
        width: 31px;
        height: 44px;
    }

    .home-banner .swiper-pagination {
        bottom: 59px !important;
    }

    .home-banner .swiper-pagination-bullet {
        width: 16px;

    }

    .home-banner .swiper-pagination-bullet-active {
        width: 30px;

    }

    .home-banner .mobile-view {
        height: 292px !important;

    }

    .home-banner .video-class-grid {
        width: 100%;
        height: 180px;
    }

    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 18px;
    }
}

@media(min-width:361px) and (max-width:425px) {
    .home-banner .video-class-grid {
        width: 100%;
        height: 212px;
    }

    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 18px;
    }

    .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 13px;
        color: #FFF;
    }
}

@media(min-width:360px) and (max-width:567px) {
    .home-banner .text-holder {
        margin-top: 6%;
    }

    .home-banner .text-holder h2 {
        font-size: 22px;
        color: white;
    }

    .home-banner .text-holder p {
        font-size: 10px;
        color: white;
    }

    .home-banner .img-overlay {
        position: absolute;
        top: 20%;
        left: 14%;
    }

    .home-banner .lottey-holder {
        bottom: 14px;
        left: 49%;
        width: 31px;
        height: 44px;
    }

    .home-banner .swiper-pagination {
        bottom: 59px !important;
    }

    .home-banner .mobile-view {
        height: 292px !important;

    }


}

@media(min-width:567px) and (max-width:767px) {
    .home-banner .swiper-pagination {
        bottom: 90px !important;
    }

    .home-banner .lottey-holder {
        position: absolute;
        bottom: 15px;
        left: 49%;
        width: 35px;
        height: 64px;
    }

    .home-banner .text-holder h2 {
        font-size: 30px;
        color: white;
    }

    .home-banner .text-holder p {
        font-size: 14px;
        color: white;

    }

    .home-banner .img-overlay {
        position: absolute;
        top: 20%;
        left: 0%;
    }

}

@media(min-width:0px) and (max-width:767px) {
    .home-banner .mobile-view {
        height: 350px;
        width: 100%;
        background-color: #000;
        display: block;
        position: relative;
    }

    .home-banner .desktop-view {
        display: block;
    }

    .home-banner .banner-slider .img-overlay {
        position: absolute;
        top: 14%;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 100%;
    }

    .home-banner .video-class-grid {
        width: 100%;
        object-fit: cover;
        height: 300px;
    }

    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 30px;
    }


}

@media(min-width:768px) and (max-width:991px) {
    .home-banner .swiper-pagination {
        bottom: 90px !important;
    }

    .home-banner .lottey-holder {
        position: absolute;
        bottom: 15px;
        left: 49%;
        width: 35px;
        height: 64px;
    }

    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 26px;
    }

    .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 14px;
    }

    .home-banner .banner-slider .img-overlay {
        top: 15%;
    }

    .home-banner .video-class-grid {
        height: 435px;
    }
}

@media(min-width:992px) and (max-width:1199px) {
    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 31px;
    }

    .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 16px;
    }

    .home-banner .banner-slider .img-overlay {
        top: 15%;
    }

    .home-banner .video-class-grid {
        height: 582px;
    }
}

@media(min-width:1200px) and (max-width:1400px) {
    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 35px;
    }

    .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 12px;
    }

    .home-banner .banner-slider .img-overlay {
        top: 15%;
    }
}

@media(min-width:1401px) and (max-width:1600px) {
    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 37px;
    }

    /* .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 12px;
    } */

    .home-banner .banner-slider .img-overlay {
        top: 15%;
    }

    .home-banner .video-class-grid {
        width: 100%;
        /* object-fit: cover; */
        height: 810px;
    }
}

@media(min-width:1601px) and (max-width:1800px) {
    .home-banner .banner-slider .img-overlay .text-holder h2 {
        font-size: 37px;
    }

    .home-banner .banner-slider .img-overlay .text-holder p {
        font-size: 12px;
    }

    .home-banner .banner-slider .img-overlay {
        top: 15%;
    }
}

.banner-slider {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Ensures video does not overflow its container */
}