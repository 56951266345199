.down-icon-banner {
    height: 112px !important;
    margin-top: -29px
        /* width: 112px; */
}

@media(min-width:360px) and (max-width:567px) {
    .down-icon-banner {
        height: 72px !important;
        margin-top: -18px
    }
}

@media(min-width:0px) and (max-width:360px) {
    .down-icon-banner {
        height: 75px !important;
        margin-top: -18px
    }
}