.our-brand-scroll {
    background: #fbfbfb;
    padding: 50px 0px;
}

.our-brand-scroll .title-our-brand h2 {
    color: #000000;
    font-size: 50px;
    font-weight: bold;
}


.our-brand-scroll .title-our-brand p {
    font-size: 16px;
    margin: auto;
    color: #00000096;
    font-family: 'Source Sans Pro' !important;
}

.our-brand-scroll .marquee-wrapper {
    text-align: center;
}

.our-brand-scroll .marquee-wrapper .container {
    overflow: hidden;
}

.our-brand-scroll .marquee-inner span {
    float: left;
    width: 50%;
}

/* .our-brand-scroll .marquee-wrapper .marquee-block {
    --total-marquee-items: 7;
    height: 150px;
    width: calc(250px * (var(--total-marquee-items)));
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    margin: 5px auto;
    padding: 30px 0;
} */

.our-brand-scroll .marquee-inner {
    display: block;
    width: 200%;
    position: absolute;
}

.our-brand-scroll .marquee-inner p {
    font-weight: 800;
    font-size: 30px;
    font-family: cursive;
}

.our-brand-scroll .marquee-inner.to-left {
    animation: marqueeLeft 15s linear infinite;
}

.our-brand-scroll .marquee-inner.to-right {
    animation: marqueeRight 15s linear infinite;
}

.our-brand-scroll .marquee-item {
    width: 230px;
    height: auto;
    display: inline-block;
    margin: 0 10px;
    float: left;
    transition: all .2s ease-out;
    border: 1px solid #d3cdcd;
    border-radius: 10px;
    padding: 20px;
}

@keyframes marqueeLeft {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

@keyframes marqueeRight {
    0% {
        left: -100%;
    }

    100% {
        left: 0;
    }
}



@media(min-width:0px) and (max-width:320px) {
    .our-brand-scroll .marquee-item {
        width: 191px;
        padding: 0;
    }

    .our-brand-scroll .marquee-wrapper .marquee-block {
        height: 85px;
        margin: 4px auto;
        padding: 25px 0;
    }

    .our-brand-scroll .title-our-brand h2 {
        font-size: 30px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .our-brand-scroll .marquee-item {
        width: 191px;
        padding: 0;
    }

    .our-brand-scroll .title-our-brand h2 {
        font-size: 30px;
    }

    .our-brand-scroll .marquee-wrapper .marquee-block {
        height: 94px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .our-brand-scroll .marquee-item {
        width: 191px;
        padding: 0;
    }

    .our-brand-scroll .title-our-brand h2 {
        font-size: 35px;
    }

    .our-brand-scroll .marquee-wrapper .marquee-block {
        height: 94px;
    }
}

@media(min-width:577px) and (max-width:768px) {
    .our-brand-scroll .title-our-brand h2 {
        font-size: 38px;
    }
}