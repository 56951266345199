@import url('https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

@font-face {
  font-family: Source Sans Pro;
  src: url('../public/assets/images/SourceSansPro-Light.otf');
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', 'sans-serif', 'SourceSansPro-Light.otf' !important;
}

.form-control:focus {
  box-shadow: none !important;
}

/* .main .modal{
  backdrop-filter: brightness(2);
} */