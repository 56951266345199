@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400&family=Poppins:wght@300;400;500&display=swap");

/*about banner-section-start */

.banner-about-us .banner-about {
  height: auto;
  width: 100%;
}

.banner-about-us .card-box {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000038;
  padding: 10px 20px 15px 35px;
  margin-top: -32px;
  position: relative;
  z-index: 5;
}

.banner-about-us .border-red-line {
  height: 23px;
  background: #ed1b24 0% 0% no-repeat padding-box;
  margin-top: 4px;
}

.banner-about-us .card-box .heading-about h4 {
  margin-bottom: 0px;
  font-size: 29px;
  font-weight: 700;
}

.banner-about-us .card-box .border-red {
  background: #ed1b24;
  height: 3px;
  width: 53px;
}

.banner-about-us .card-box .border-line {
  height: 1px;
  background: #000000;
  width: 90px;
  margin-top: 1px;
}

.banner-about-us .card-box .paragraph-holder p {
  font-size: 14px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 20;
  line-clamp: 20;
  /* text-align: justify; */
  margin-top: 11px;
}

.banner-about-us .card-box .paragraph-holder h6 {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.banner-about-us .card-box .paragraph-holder span {
  font-size: 14px !important;
  font-weight: 300 !important;
}

.banner-about-us .paragraph-holder ul {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 20;
  text-align: justify;
}

/* .about-slider .slider-started .swiper-slide-next {
  width: 50% !important;
}
.about-slider .slider-started .swiper-slide-active{
  width: 300px !important;
}
.about-slider .slider-started .swiper-slide-active .scroll-img {
  width: 300px !important;
  object-fit: cover;
  border-radius: 0px 50px 50px 0px;
} */

/*about banner-section-end */

/******************* banner media query started *************************/

@media (min-width: 0px) and (max-width: 320px) {
  .banner-about-us .border-red-line {
    margin-top: 0px;
    width: 100%;
  }

  .banner-about-us .card-box {
    margin-top: 0px;
    padding: 10px 10px 15px 10px;
  }

  .banner-about-us .card-box .heading-about h4 {
    font-size: 20px;
  }

  .banner-about-us .card-box .paragraph-holder p {
    font-size: 13px;
    text-align: justify;
  }

  .banner-about-us .card-box .paragraph-holder h6 {
    font-size: 13px;
    text-align: justify;
  }

  .banner-about-us .card-box .paragraph-holder span {
    font-size: 13px !important;
  }

  .banner-about-us .card-box .border-line {
    width: 54px;
  }

  .banner-about-us .card-box .border-red {
    width: 37px;
  }

  .Total-security .swiper-pagination-bullet {
    width: 13px !important;
  }

  .Total-security .swiper-pagination-bullet-active {
    width: 20px !important;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .banner-about-us .card-box {
    margin-top: 0px;
    padding: 10px 10px 15px 10px;
  }

  .banner-about-us .border-red-line {
    margin-top: 0px;
    width: 100%;
  }

  .banner-about-us .card-box .heading-about h4 {
    font-size: 23px;
  }

  .banner-about-us .card-box .paragraph-holder p {
    font-size: 13px;
    text-align: justify;
  }

  .banner-about-us .card-box .paragraph-holder h6 {
    font-size: 13px;
    text-align: justify;
  }

  .banner-about-us .card-box .paragraph-holder span {
    font-size: 13px !important;
  }

  .banner-about-us .card-box .border-line {
    width: 60px;
  }

  .banner-about-us .card-box .border-red {
    width: 49px;
  }

  .Total-security .swiper-pagination-bullet {
    width: 13px !important;
  }

  .Total-security .swiper-pagination-bullet-active {
    width: 20px !important;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .banner-about-us .card-box {
    margin-top: 0px;
    padding: 10px 20px 15px 20px;
  }

  .banner-about-us .border-red-line {
    margin-top: 0px;
    width: 100%;
  }

  .banner-about-us .card-box .heading-about h4 {
    font-size: 24px;
  }

  .banner-about-us .card-box .paragraph-holder p {
    font-size: 13px;
    text-align: justify;
  }

  .banner-about-us .card-box .paragraph-holder h6 {
    font-size: 13px;
    text-align: justify;
  }

  .banner-about-us .card-box .paragraph-holder span {
    font-size: 13px !important;
  }

  .banner-about-us .card-box .border-line {
    width: 65px;
  }

  .banner-about-us .card-box .border-red {
    width: 47px;
  }

  .Total-security .swiper-pagination-bullet {
    width: 13px !important;
  }

  .Total-security .swiper-pagination-bullet-active {
    width: 20px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .banner-about-us .card-box {
    margin-top: 0px;
  }

  .banner-about-us .border-red-line {
    margin-top: 0px;
    width: 100%;
  }

  .banner-about-us .card-box .paragraph-holder h6 {
    text-align: justify;
  }

  .banner-about-us .card-box .heading-about h4 {
    font-size: 25px;
  }

  .banner-about-us .card-box .border-line {
    width: 65px;
  }

  .banner-about-us .card-box .border-red {
    width: 49px;
  }

  .Total-security .swiper-pagination-bullet {
    width: 13px !important;
  }

  .Total-security .swiper-pagination-bullet-active {
    width: 20px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .banner-about-us .card-box .border-line {
    width: 76px;
  }

  .banner-about-us .card-box {
    margin-top: -19px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .banner-about-us .card-box .border-line {
    width: 76px;
  }

  .banner-about-us .card-box .border-line {
    width: 75px;
  }

  .banner-about-us .card-box {
    /* margin-top: -19px; */
  }
}

/******************* banner media query end *************************/

/* about-slider-section-start */
.about-slider {
  margin-top: 5%;
  margin-bottom: 2%;
}

.arrow-image {
  height: 50px;
  width: 50px;
}

.about-slider .swiper-button-prev {
  display: none;
}

.about-slider .swiper-button-next {
  display: none;
}

/* .about-slider .scroll-img {
  height: 300px;
  border-radius: 44px;
} */


/* .about-slider .scroll-img {
 filter: drop-shadow(10px 10px 20px #ccc);
} */







.about-slider .swiper-slide img {
  display: block;
  width: 100%;
}

.about-slider .swiper-slide {
  width: 60%;
}

.about-slider .slider-started .main-slider {
  position: relative;
}

.about-slider .slider-started .main-slider .arrow-back,
.about-slider .slider-started .main-slider .arrow-next {
  position: absolute;
  bottom: 166px;
  left: 0;
  right: 0;
  cursor: pointer;
}

.about-slider .slider-started .main-slider .arrow-back::after {
  content: url("../../public/assets/images/icon/arrow-next.png");
  position: absolute;
  top: -89px !important;
  right: -7px;
}

.about-slider .slider-started .main-slider .arrow-next::before {
  content: url("../../public/assets/images/icon/arrow-back.png");

  position: absolute;
  top: -89px !important;
  left: -7px;

}

/* about-slider-section-end*/

/* ***************************about slider media Query started********************* */

@media (min-width: 0px) and (max-width: 320px) {


  .about-slider .slider-started .main-slider .arrow-back::after {
    content: url("../../public/assets/images/icon/chevron-down1.png");
    top: -33px !important;
    right: 1px;
  }



  .about-slider .slider-started .swiper {
    padding-bottom: 30px !important;
  }

  .about-slider .slider-started .main-slider .arrow-next::before {
    content: url("../../public/assets/images/icon/chevron-down.png");
    top: -33px !important;
    left: -1px
  }

  .about-slider .slider-started .main-slider .arrow-back,
  .about-slider .slider-started .main-slider .arrow-next {
    bottom: 34px;
  }

  .about-slider .scroll-img {
    border-radius: 10px;
    height: auto;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .about-slider .scroll-img {
    height: auto;
    border-radius: 10px;
  }

  .about-slider .slider-started .main-slider .arrow-back::after {

    content: url("../../public/assets/images/icon/chevron-down1.png");
    top: -21px !important ;
  }

  .about-slider .slider-started .main-slider .arrow-next::before {
    content: url("../../public/assets/images/icon/chevron-down.png");
    top: -21px !important;
  }

  .about-slider .slider-started .main-slider .arrow-back,
  .about-slider .slider-started .main-slider .arrow-next {
    bottom: 42px;
  }

  .about-slider .slider-started .swiper {
    padding-bottom: 22px !important;
  }

}

@media (min-width: 486px) and (max-width: 575px) {
  .about-slider .scroll-img {
    height: auto;
    border-radius: 10px;
  }

  .about-slider .slider-started .main-slider .arrow-back::after {}

  .about-slider .slider-started .main-slider .arrow-next::before {}

  .about-slider .slider-started .main-slider .arrow-back,
  .about-slider .slider-started .main-slider .arrow-next {
    bottom: 61px;
  }


}

@media (min-width: 576px) and (max-width: 767px) {
  .about-slider .scroll-img {
    height: auto;
  }

  .about-slider .slider-started .main-slider .arrow-back,
  .about-slider .slider-started .main-slider .arrow-next {
    bottom: 54px;
  }

  .about-slider .scroll-img {
    border-radius: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-slider .scroll-img {
    height: auto;
    border-radius: 10px;
  }

  .about-slider .slider-started .main-slider .arrow-back::after {
    margin-top: 86px;
  }

  .about-slider .slider-started .main-slider .arrow-next::before {
    margin-top: 86px;
  }

}

/* @media(min-width:992px) and (max-width:1200px) {

  .about-slider .slider-started .main-slider .arrow-back,
  .about-slider .slider-started .main-slider .arrow-next {
    bottom: 100px;
  }
} */

/* ***************************about slider media Query end********************* */

/* Total-security-section-start */

.Total-security .heading-about h4 {
  font-size: 29px;
  font-weight: bold;
  margin-bottom: 0px;
}

.Total-security .border-red {
  background: #ed1b24;
  height: 3px;
  width: 68px;
}

.Total-security .border-line {
  height: 1px;
  background: #000000;
  width: 107px;
  margin-top: 1px;
}

.Total-security .text-holder p {
  font-size: 14px;
}

.Total-security .box-circle {
  width: 114px;
  height: 114px;
  border-radius: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  margin: auto;
}

.Total-security .box-circle-two {
  width: 114px;
  height: 114px;
  border-radius: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  margin: auto;
  transition: all 0.8s ease;
}

.Total-security .box-circle:hover .box-circle-two {
  background: #ed1b24 0% 0% no-repeat padding-box;
}

.Total-security .box-circle:hover {
  /* background: #ed1b24 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 6px #ed1b24;
  transition-delay: 0.3s;
}

.Total-security .box-circle:hover .tool-icon {
  filter: brightness(0) invert(1);
}

.Total-security .box-circle .tool-icon {
  width: 66px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.Total-security .name-holder h5 {
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.Total-security .name-holder p {
  font-size: 13px;
  text-align: center;
  max-width: 200px;
  margin: auto;
  font-family: "Source Sans Pro";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.Total-security .swiper-button-prev:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAWCAYAAAAb+hYkAAAABHNCSVQICAgIfAhkiAAAAJdJREFUOE+V020NgEAIBmAvgTawgjbQKEaxiRFsoFGMYATBHRtjfJ0bP9zu2SkvlC73THDsgnqh1pIwBIZ6do+QBOhmD2lgA3RYyAR4lYZcoKEQSJQCHKUBoSZACFPuWch/W73QsXsP1NiK8PPultsopybIw01DOREpaI2R+4/ewJowWg0VZpZQwvS6cxiuOw0KdvWsL8sHVP0ox1KzKuYAAAAASUVORK5CYII=);
  position: absolute;
  top: -28px;
}

.Total-security .swiper-button-next:after {
  content: url("../../public/assets/images/icon/arrow-next.png");
  position: absolute;
  top: -28px;
}

.Total-security .swiper-pagination-bullet {
  width: 21px;
  height: 6px;
  border-radius: 5px;
  opacity: 1;
  background-color: #d5d5d5;
}

.Total-security .swiper-pagination-bullet-active {
  width: 41px;
  background: #000;
}

.Total-security .name-holder {
  margin-top: 7%;
}

/* Total-security-section-end*/

/* ***************************toatal security media Query started******************************** */
@media (min-width: 0px) and (max-width: 320px) {
  .Total-security .heading-about h4 {
    font-size: 20px;
  }

  .Total-security .border-line {
    width: 82px;
  }

  .Total-security .border-red {
    width: 45px;
  }

  .Total-security .text-holder p {
    font-size: 12px;
  }

  .Total-security .name-holder h5 {
    font-size: 12px;
  }

  .Total-security .name-holder p {
    font-size: 13px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Total-security .heading-about h4 {
    font-size: 23px;
  }

  .Total-security .border-line {
    width: 93px;
  }

  .Total-security .border-red {
    width: 52px;
  }

  .Total-security .name-holder h5 {
    font-size: 14px;
  }

  .Total-security .name-holder p {
    font-size: 13px;
  }

  .about-slider .slider-started .swiper .blured-img {
    top: 15px !important;
    left: 7px !important;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Total-security .heading-about h4 {
    font-size: 24px;
  }

  .Total-security .border-line {
    width: 99px;
  }

  .Total-security .border-red {
    width: 55px;
  }

  .Total-security .name-holder h5 {
    font-size: 15px;
  }

  .Total-security .name-holder p {
    font-size: 13px;
  }

  .about-slider .slider-started .swiper .blured-img {
    top: 15px !important;
    left: 7px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Total-security .heading-about h4 {
    font-size: 25px;
  }

  .Total-security .border-red {
    width: 57px;
  }

  .Total-security .box-circle {
    width: 105px;
    height: 105px;
  }

  .Total-security .box-circle .tool-icon {
    width: 55px;
  }

  .Total-security .name-holder h5 {
    font-size: 13px;
  }

  .Total-security .name-holder p {
    font-size: 13px;
  }

  .Total-security .border-line {
    width: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1299px) {
  .Total-security .name-holder p {
    font-size: 13px;
  }

  .Total-security .name-holder h5 {
    font-size: 15px;
  }
}

/* ***************************toatal security media Query started******************************** */

/* objective-section-start */
.objective-section {
  margin-top: 2%;
}

.objective-section .bg-image {
  background-image: url("../../public/assets/images/banner/perspective-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 483px;
  margin-bottom: 3%;
}

.objective-section .heading-holder {
  border-left: 1px solid #000;
  /* margin-top: 26%; */
}

.objective-section .heading-holder h5 {
  font-size: 50px;
  font-weight: bold;
  color: #000000;
}

.objective-section .heading-holder h6 {
  font-size: 43px;
}

.objective-section .card {
  background: #f6f6f6;
  border-radius: 0%;
  border: none;
  padding: 10px;
  position: relative;
  margin-top: 32%;
  min-height: 335px;
  height: 70%;
}

.objective-section .card:hover {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ed1b2459;
}

.objective-section .heading-about h4 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}

.objective-section .border-red {
  background: #ed1b24;
  height: 3px;
  width: 35px;
}

.objective-section .border-line {
  height: 1px;
  background: #000000;
  width: 61px;
  margin-top: 1px;
}

.objective-section .border-red1 {
  background: #ed1b24;
  height: 3px;
  width: 35px;
}

.objective-section .border-line1 {
  height: 1px;
  background: #000000;
  width: 36px;
  margin-top: 1px;
}

.objective-section .text-holder p {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}


.objective-section .card .mission {
  position: absolute;
  top: -15%;
  z-index: 1;
  left: 37%;
  background-color: #fff;
  border-radius: 100%;
}

/* ********************objective media query started**************************** */

@media (min-width: 0px) and (max-width: 320px) {
  .objective-section .heading-holder h5 {
    font-size: 25px;
  }

  .objective-section .heading-holder h6 {
    font-size: 25px;
  }

  .objective-section .bg-image {
    height: auto;
  }

  .objective-section .heading-about h4 {
    font-size: 22px;
  }

  .objective-section .border-line {
    width: 55px;
  }

  .objective-section .text-holder p {
    font-size: 14px;
    text-align: justify;
  }

  .objective-section .card {
    min-height: 292px;
    margin-top: 20%;
  }

  .objective-section .heading-holder {
    border-left: 1px solid #000;
    margin-top: 10%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .objective-section .heading-holder h5 {
    font-size: 24px;
  }

  .objective-section .heading-holder h6 {
    font-size: 24px;
  }

  .objective-section .bg-image {
    height: auto;
  }

  .objective-section .text-holder p {
    text-align: justify;
  }

  .objective-section .border-line {
    width: 51px;
  }

  .objective-section .border-red {
    width: 37px;
  }

  .objective-section .border-line1 {
    width: 42px;
  }

  .objective-section .border-red1 {
    width: 29px;
  }

  .objective-section .card {
    margin-top: 16%;
  }

  .objective-section .card {
    min-height: 292px;
  }

  .objective-section .heading-holder {
    margin-top: 14%;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .objective-section .bg-image {
    height: auto;
  }

  .objective-section .card {
    margin-top: 16%;
  }

  .objective-section .heading-holder {
    margin-top: 12%;
  }

  .objective-section .heading-holder h5 {
    font-size: 38px;
  }

  .objective-section .heading-holder h6 {
    font-size: 38px;
  }

  .objective-section .border-line1 {
    width: 40px;
  }

  .objective-section .border-red1 {
    width: 31px;
  }

  .objective-section .card {
    min-height: 292px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .objective-section .bg-image {
    height: auto;
  }

  .objective-section .card {
    margin-top: 16%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .objective-section .heading-holder h5 {
    font-size: 27px;
    font-weight: bold;
  }

  .objective-section .heading-holder h6 {
    font-size: 27px;
  }

  .objective-section .heading-holder {
    /* margin-top: 35%; */
  }

  .objective-section .text-holder p {
    font-size: 13px;
  }

  .objective-section .heading-about h4 {
    font-size: 22px;
  }

  .objective-section .card .mission {
    left: 32%;
  }
}

@media (min-width: 992px) and (max-width: 1299px) {
  .objective-section .heading-holder h5 {
    font-size: 42px;
    font-weight: bold;
  }

  .objective-section .heading-holder h6 {
    font-size: 39px;
  }

  .objective-section .heading-holder {
    /* margin-top: 35%; */
  }
}

/* our-strength-section-start */
.our-strength {
  margin-top: 3%;
  margin-bottom: 7%;
}

.our-strength .heading-about h4 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}

.our-strength .border-red {
  background: #ed1b24;
  height: 3px;
  width: 75px;
}

.our-strength .border-line {
  height: 1px;
  background: #000000;
  width: 92px;
  margin-top: 1px;
}

.our-strength .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000000, 0px 3px 11px #ed1b2421;
  opacity: 1;
  border: none;
  height: auto;
  min-height: 432px;
  position: relative;
  padding-left: 10px;
  padding-right: 15px;
}

.our-strength .circle-holder {
  position: absolute;
  margin-top: 56px;
  z-index: 1;
}

.our-strength .circle-holder::before {
  content: "";
  display: block;
  width: 29px;
  height: 1px;
  background: #ed1b24 0% 0% no-repeat padding-box;
  position: absolute;
  left: -12px;
  top: 49%;
  z-index: -2;
}

.our-strength .circle-holder::after {
  content: "";
  display: block;
  width: 32px;
  height: 1px;
  background: #ed1b24 0% 0% no-repeat padding-box;
  position: absolute;
  right: -7px;
  top: 49%;
  z-index: -2;
}

.our-strength .circle-img {
  width: 93px;
  height: 94px;
}

.our-strength .hover-circle:hover .circle-img {
  /* filter: invert(18%) sepia(60%) saturate(3519%) hue-rotate(342deg) brightness(70%) contrast(90%); */
  filter: invert(18%) sepia(109%) saturate(7003%) hue-rotate(350deg) brightness(90%) contrast(87%);
}

.our-strength .imag-holder .hand-img {
  width: 45px;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.our-strength .imag-holder {
  width: 75px;
  height: 72px;
  border-radius: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 8px #0000003d;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  position: absolute;
  top: 11px;
  z-index: 1;
  left: 9px;
}

.our-strength .up-line {
  position: absolute;
  top: -38%;
  left: 39px;
  z-index: -1;
}

.our-strength .up-line .upline-img {
  height: 60px;
}

.our-strength .down-line {
  position: absolute;
  top: 70px;
  left: 39px;
  z-index: -1;
}

.our-strength .down-line .downline-img {
  height: 60px;
}

.our-strength .circle-name p {
  font-size: 11px;
  margin-bottom: 0%;
  text-align: center;
  margin-left: 0px;
}

.our-strength .circle-name1 p {
  font-size: 10px;
  margin-top: 36px;
  text-align: center;
}

.our-strength .line-row {
  justify-content: space-between;
}

.our-strength .hover-circle:hover .circle-name p {
  /* font-weight: bold; */
  font-size: 11px;
}

.our-strength .hover-circle:hover .circle-name1 p {
  font-weight: 600;
  font-size: 10px;
}

.our-strength .circle-holder1 {
  position: absolute;
  margin-top: 139px;
  z-index: 1;
}

.our-strength .circle-holder1::before {
  content: "";
  display: block;
  width: 65px;
  height: 1px;
  background: #ed1b24 0% 0% no-repeat padding-box;
  position: absolute;
  left: -51px;
  top: 23%;
  z-index: -2;
}

.our-strength .circle-holder1::after {
  content: "";
  display: block;
  width: 106px;
  height: 1px;
  background: #ed1b24 0% 0% no-repeat padding-box;
  position: absolute;
  right: -49px;
  top: 23%;
  z-index: -2;
}

.our-strength .commit-name {
  display: none;
}

.our-strength .commit-name1 {
  display: none;
}

.our-strength .commit-name2 {
  display: none;
}

.our-strength .commit-name3 {
  display: none;
}

.our-strength .commit-name4 {
  display: none;
}


.about-slider .slider-started .image-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.about-slider .slider-started .image-container .scroll-img {
  border-radius: 30px;
}

.about-slider .slider-started .swiper {
  padding-bottom: 100px;
}

.about-slider .slider-started .swiper .blured-img {
  position: absolute;
  top: 35px;
  left: 7px;
  z-index: -9;
  border-radius: 30px;
  filter: blur(5px);
}





@media (min-width: 0px) and (max-width: 320px) {
  .our-strength .heading-about h4 {
    font-size: 20px;
  }

  .our-strength .border-line {
    width: 80px;
  }

  .our-strength .border-red {
    width: 50px;
  }

  .about-slider .slider-started .swiper .blured-img {
    top: 10px;
  }

  .about-slider .slider-started .image-container .scroll-img {
    border-radius: 19px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .our-strength .heading-about h4 {
    font-size: 23px;
  }

  .our-strength .border-line {
    width: 90px;
  }

  .our-strength .border-red {
    width: 60px;
  }

  .about-slider .slider-started .image-container .scroll-img {
    border-radius: 19px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .objective-section .border-red1 {
    width: 29px;
  }

  .objective-section .border-line1 {
    width: 44px;
  }

  .objective-section .heading-holder {
    margin-top: 13%;
  }

  .objective-section .heading-holder h5 {
    font-size: 40px;
  }

  .objective-section .heading-holder h6 {
    font-size: 37px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .our-strength .circle-holder {
    margin-top: 63px;
  }

  .our-strength .circle-holder1::before {
    width: 37px;
    left: -32px;
    /* top: 22%; */
    top: 43px;
  }

  .our-strength .circle-holder::before {
    top: 50%;
  }

  .our-strength .imag-holder {
    width: 69px;
    height: 66px;
  }

  .our-strength .circle-img {
    width: 88px;
    height: 88px;
  }

  .our-strength .up-line {
    position: absolute;
    top: -38%;
    left: 36px;
    z-index: -1;
  }

  .our-strength .hover-circle:hover .circle-name p {
    font-weight: 400;
    font-size: 11px;
  }

  .our-strength .circle-name p {
    font-size: 9px;
  }

  .our-strength .circle-holder1 {
    margin-top: 153px;
  }

  .our-strength .line-row {
    justify-content: space-around;
  }

  .our-strength .card-effect3 {
    margin-top: 91%;
  }

  .our-strength .circle-name1 p {
    padding-top: 10px;
  }

  .our-strength .hover-circle:hover .circle-name1 p {
    padding-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .our-strength .line-row {
    justify-content: left;
  }

  .our-strength .card-effect {
    margin-top: 254px;
  }

  .our-strength .card-effect1 {
    margin-top: 139%;
  }

  .our-strength .circle-holder1 {
    position: absolute;
    margin-top: 139px;
    z-index: 1;
  }

  .our-strength .card {
    min-height: 600px;
  }

  .our-strength .card-effect2 {
    margin-top: 239px;
  }

  .card-effect3 {
    margin-top: 65px;
  }

  .our-strength .circle-holder {
    margin-top: 55px;
  }

  .our-strength .circle-holder::after {
    width: 32px;
    right: -16px;
    top: 49%;
  }

  .our-strength .circle-holder1::after {
    width: 106px;
    right: -55px;
    top: 22%;
  }

  .our-strength .card-effect-4 {
    margin-top: 57px;
  }

  .our-strength .circle-name p {
    font-size: 10px;
  }

  .our-strength .hover-circle:hover .circle-name p {
    font-weight: 600;
    font-size: 10px;
  }

  .our-strength .circle-name1 p {
    font-size: 10px;
  }

  .our-strength .hover-circle:hover .circle-name1 p {
    /* padding-top: 10px; */
    font-size: 10px;
  }

  .our-strength .customer-desktop {
    /* text-align: left !important; */
    margin-right: 33px;
  }

  .about-slider .slider-started .main-slider .arrow-back::after {
    top: -38px !important;
    right: -7px;
  }

  .about-slider .slider-started .main-slider .arrow-next::before {
    top: -38px !important;
    left: -7px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .our-strength .circle-holder1::before {
    display: none;
  }

  .our-strength .circle-holder1::after {
    display: none;
  }

  .our-strength .circle-holder::before {
    display: none;
  }

  .our-strength .circle-holder::after {
    display: none;
  }

  .our-strength .circle-holder {
    margin-top: 0px;
  }

  .our-strength .circle-holder1 {
    margin-top: 0px;
  }

  .our-strength .hover-circle {
    margin-top: 97px;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .our-strength .up-line {
    transform: rotate(90deg);
    top: 68%;
    /* left: 39px; */
    z-index: -1;
    right: -60%;
  }

  .our-strength .down-line {
    transform: rotate(88deg);
    top: 19px;
    left: -4px;
  }

  .our-strength .circle-name {
    position: absolute;
    top: 39px;
    right: 147px;
    display: none;
  }

  .our-strength .circle-name1 {
    position: absolute;
    top: 0px;
    left: -158px;
  }

  .our-strength .vl {
    border-left: 0.5px solid #ed1b24;
    height: 962px;
    position: absolute;
    left: 46px;
    margin-left: 0px;
    top: 5%;
  }

  .our-strength .card {
    padding-bottom: 10%;
  }

  .our-strength .name-5 {
    bottom: 0% !important;
    position: absolute;
    right: 0%;
  }

  .our-strength .commit-name {
    display: block;
    font-size: 13px;
    position: absolute;
    right: 25%;
    top: 8%;
  }

  .our-strength .commit-name1 {
    display: block;
    font-size: 13px;
    position: absolute;
    right: 22%;
    /* top: 18%; */
    margin-top: 16px;
  }

  .our-strength .commit-name2 {
    display: block;
    font-size: 13px;
    position: absolute;
    right: 23%;
    /* top: 28%; */
    margin-top: 10px;
  }

  .our-strength .commit-name3 {
    display: block;
    font-size: 13px;
    position: absolute;
    right: 19%;
    /* top: 38%; */
    margin-top: 17px;
  }

  .our-strength .commit-name4 {
    display: block;
    font-size: 13px;
    position: absolute;
    right: 23%;

    bottom: 6%;
  }

  .our-strength .circle-name1 p {
    font-size: 13px;
    margin-top: 21px;
  }

  .our-strength .hover-circle:hover .circle-name1 p {
    font-weight: 600;
    font-size: 13px;
  }

  .our-strength .hover-circle:hover .commit-name4 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name3 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name2 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name1 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name p {
    font-weight: 600;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .our-strength .circle-holder1::before {
    display: none;
  }

  .our-strength .circle-holder1::after {
    display: none;
  }

  .our-strength .circle-holder::before {
    display: none;
  }

  .our-strength .circle-holder::after {
    display: none;
  }

  .our-strength .circle-holder {
    margin-top: 0px;
  }

  .our-strength .circle-holder1 {
    margin-top: 0px;
  }

  .our-strength .hover-circle {
    margin-top: 97px;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .our-strength .up-line {
    transform: rotate(90deg);
    top: 68%;
    /* left: 39px; */
    z-index: -1;
    right: -60%;
  }

  .our-strength .down-line {
    transform: rotate(88deg);
    top: 19px;
    left: -4px;
  }

  .our-strength .circle-name {
    position: absolute;
    top: 39px;
    right: 147px;
    display: none;
  }

  .our-strength .circle-name1 {
    position: absolute;
    top: -8px;
    left: -138px;
  }

  .our-strength .vl {
    border-left: 0.5px solid #ed1b24;
    height: 962px;
    position: absolute;
    left: 46px;
    margin-left: 0px;
    top: 5%;
  }

  .our-strength .card {
    padding-bottom: 10%;
  }

  .our-strength .name-5 {
    bottom: 0% !important;
    position: absolute;
    right: 0%;
  }

  .our-strength .commit-name {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 12%;
    top: 87px;
  }

  .our-strength .commit-name1 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 9%;
    margin-top: 15px;
  }

  .our-strength .commit-name2 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 11%;
    margin-top: 12px;
  }

  .our-strength .commit-name3 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 5%;
    margin-top: 17px;
  }

  .our-strength .commit-name4 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 11%;
    /* top: 48%; */
    bottom: 69px;
  }

  .our-strength .circle-name1 p {
    font-size: 11px;
  }

  .our-strength .hover-circle:hover .circle-name1 p {
    font-weight: 600;
    font-size: 11px;
  }

  .our-strength .card {
    padding-bottom: 19%;
  }

  .our-strength .hover-circle:hover .commit-name4 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name3 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name2 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name1 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name p {
    font-weight: 600;
  }
}

@media (min-width: 360px) and (max-width: 424px) {
  .our-strength .imag-holder {
    width: 58px;
    height: 58px;
    top: 8px;
    left: 8px;
  }

  .our-strength .circle-img {
    width: 74px;
    height: 74px;
  }

  .our-strength .circle-holder1::before {
    display: none;
  }

  .our-strength .circle-holder1::after {
    display: none;
  }

  .our-strength .circle-holder::before {
    display: none;
  }

  .our-strength .circle-holder::after {
    display: none;
  }

  .our-strength .circle-holder {
    margin-top: 0px;
  }

  .our-strength .circle-holder1 {
    margin-top: 0px;
  }

  .our-strength .hover-circle {
    margin-top: 97px;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .our-strength .up-line {
    transform: rotate(90deg);
    top: 29%;
    z-index: -1;
    right: -20%;
  }

  .our-strength .down-line {
    transform: rotate(88deg);
    top: 9px;
    left: 0px;
  }

  .our-strength .circle-name {
    position: absolute;
    top: 39px;
    right: 147px;
    display: none;
  }

  .our-strength .circle-name1 {
    position: absolute;
    top: -18px;
    left: -135px;
  }

  .our-strength .vl {
    border-left: 0.5px solid #ed1b24;
    height: 881px;
    position: absolute;
    left: 37px;
    margin-left: 0px;
    top: 5%;
  }

  .our-strength .card {
    padding-bottom: 10%;
  }

  .our-strength .name-5 {
    bottom: 0% !important;
    position: absolute;
    right: 0%;
  }

  .our-strength .commit-name {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 12%;
    top: 87px;
  }

  .our-strength .commit-name1 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 10%;
    margin-top: 9px;
  }

  .our-strength .commit-name2 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 12%;
    margin-top: 16px;
  }

  .our-strength .commit-name3 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 7%;
    margin-top: 11px;
  }

  .our-strength .commit-name4 {
    display: block;
    font-size: 11px;
    position: absolute;
    right: 13%;
    bottom: 60px;
  }

  .our-strength .circle-name1 p {
    font-size: 11px;
  }

  .our-strength .hover-circle:hover .circle-name1 p {
    font-weight: 600;
    font-size: 11px;
  }

  .our-strength .imag-holder .hand-img {
    width: 41px;
  }

  .our-strength .card {
    padding-bottom: 19%;
  }

  .our-strength .hover-circle:hover .commit-name4 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name3 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name2 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name1 p {
    font-weight: 600;
  }

  .our-strength .hover-circle:hover .commit-name p {
    font-weight: 600;
  }
}

@media (min-width: 0px) and (max-width: 359px) {
  .our-strength .imag-holder {
    width: 51px;
    height: 51px;
    top: 6px;
    left: 6px;
  }

  .our-strength .circle-img {
    width: 63px;
    height: 63px;
  }

  .our-strength .circle-holder1::before {
    display: none;
  }

  .our-strength .circle-holder1::after {
    display: none;
  }

  .our-strength .circle-holder::before {
    display: none;
  }

  .our-strength .circle-holder::after {
    display: none;
  }

  .our-strength .circle-holder {
    margin-top: 0px;
  }

  .our-strength .circle-holder1 {
    margin-top: 0px;
  }

  .our-strength .hover-circle {
    margin-top: 97px;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .our-strength .up-line {
    transform: rotate(90deg);
    top: 22%;
    z-index: -1;
    right: 0%;
  }

  .our-strength .down-line {
    transform: rotate(88deg);
    top: 9px;
    left: 4px;
  }

  .our-strength .circle-name {
    position: absolute;
    top: 39px;
    right: 147px;
    display: none;
  }

  .our-strength .circle-name1 {
    position: absolute;
    top: -18px;
    left: -114px;
  }

  .our-strength .vl {
    border-left: 0.5px solid #ed1b24;
    height: 881px;
    position: absolute;
    left: 31px;
    margin-left: 0px;
    top: 5%;
  }

  .our-strength .card {
    padding-bottom: 10%;
  }

  .our-strength .name-5 {
    bottom: 0% !important;
    position: absolute;
    right: 0%;
  }

  .our-strength .commit-name {
    display: block;
    font-size: 8px;
    position: absolute;
    right: 12%;
    top: 87px;
  }

  .our-strength .commit-name1 {
    display: block;
    font-size: 8px;
    position: absolute;
    right: 8%;
    margin-top: 12px;
  }

  .our-strength .commit-name2 {
    display: block;
    font-size: 8px;
    position: absolute;
    right: 12%;
    margin-top: 12px;
  }

  .our-strength .commit-name3 {
    display: block;
    font-size: 8px;
    position: absolute;
    right: 6%;
    margin-top: 16px;
  }

  .our-strength .commit-name4 {
    display: block;
    font-size: 8px;
    position: absolute;
    right: 10%;
    bottom: 45px;
  }

  .our-strength .circle-name1 p {
    font-size: 8px;
    margin-top: 29px;
  }

  .our-strength .hover-circle:hover .circle-name1 p {
    font-weight: 600;
    font-size: 8px;
  }

  .our-strength .hover-circle:hover .commit-name4 p {
    font-weight: 600;
    font-size: 8px;
  }

  .our-strength .hover-circle:hover .commit-name3 p {
    font-weight: 600;
    font-size: 8px;
  }

  .our-strength .hover-circle:hover .commit-name2 p {
    font-weight: 600;
    font-size: 8px;
  }

  .our-strength .hover-circle:hover .commit-name1 p {
    font-weight: 600;
    font-size: 8px;
  }

  .our-strength .hover-circle:hover .commit-name p {
    font-weight: 600;
    font-size: 10px;
  }

  .our-strength .imag-holder .hand-img {
    width: 34px;
  }

  .our-strength .up-line .upline-img {
    height: 43px;
  }

  .our-strength .down-line .downline-img {
    height: 43px;
  }

  .our-strength .card {
    padding-bottom: 19%;
  }
}