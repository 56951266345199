.mission-lottey {
    width: 95px;
    height: 95px;
}

@media(min-width:768px) and (max-width:991px) {
    .mission-lottey {
        width: 85px;
        height: 84px;
    }
}